import React from 'react'
import { useRef } from 'react';
import { useIsVisible } from '../components/Visible';

import { Helmet } from 'react-helmet';

const TITLE = 'About Us - Benchmark Tutoring';

const About = () => {

  const ref0 = useRef();
  const isVisible0 = useIsVisible(ref0);

  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-white flex items-center justify-center">
        <div className="bg-[#8FCB9B] grid justify-center relative w-full overflow-hidden mb-24">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g mask="url(&quot;#SvgjsMask1042&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(143, 203, 155, 1)"></rect>
                <use xlinkHref="#SvgjsSymbol1049" x="0" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1049" x="720" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1049" x="1440" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1049" x="2160" y="0"></use>
            </g>
            <defs>
                <mask id="SvgjsMask1042">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
                <path d="M-1 0 a1 1 0 1 0 2 0 a1 1 0 1 0 -2 0z" id="SvgjsPath1047"></path>
                <path d="M-3 0 a3 3 0 1 0 6 0 a3 3 0 1 0 -6 0z" id="SvgjsPath1046"></path>
                <path d="M-5 0 a5 5 0 1 0 10 0 a5 5 0 1 0 -10 0z" id="SvgjsPath1043"></path>
                <path d="M2 -2 L-2 2z" id="SvgjsPath1048"></path>
                <path d="M6 -6 L-6 6z" id="SvgjsPath1044"></path>
                <path d="M30 -30 L-30 30z" id="SvgjsPath1045"></path>
            </defs>
            <symbol id="SvgjsSymbol1049">
                <use xlinkHref="#SvgjsPath1043" x="30" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="30" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="30" y="150" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1046" x="30" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="30" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="30" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="30" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="30" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="30" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="30" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="90" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="90" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="90" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="90" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="90" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="90" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="90" y="390" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1047" x="90" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="90" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="90" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="150" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="150" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="150" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="150" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="150" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="150" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="150" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="150" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="150" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="150" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="210" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="210" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="210" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="210" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="210" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="270" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="270" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="270" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="270" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="270" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="270" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="270" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="270" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="270" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="270" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="330" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="330" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="330" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="330" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="330" y="270" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1044" x="330" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1046" x="330" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="330" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="330" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="330" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1046" x="390" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="390" y="90" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1043" x="390" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="390" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="390" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="390" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="390" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="390" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="390" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="390" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="450" y="90" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1044" x="450" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="450" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="450" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="450" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="510" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="510" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="510" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="510" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="510" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="510" y="330" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1045" x="510" y="390" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1043" x="510" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="510" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="510" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="570" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="570" y="90" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1044" x="570" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="570" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="570" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="570" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="570" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="570" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="570" y="510" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1046" x="570" y="570" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1046" x="630" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1046" x="630" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="630" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="630" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="630" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="630" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="630" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="630" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="630" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1045" x="630" y="570" stroke="rgba(91, 146, 121, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1043" x="690" y="30" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="690" y="90" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1047" x="690" y="150" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="690" y="210" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1048" x="690" y="270" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1046" x="690" y="330" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="690" y="390" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="690" y="450" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1043" x="690" y="510" stroke="rgba(91, 146, 121, 1)"></use>
                <use xlinkHref="#SvgjsPath1044" x="690" y="570" stroke="rgba(91, 146, 121, 1)"></use>
            </symbol>
          </svg>
          <h1 className="hidden sm:block font-serif text-5xl text-black text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2">
            About Benchmark Tutoring...
          </h1>
          <div className="block sm:hidden font-serif w-9/12 text-4xl text-black text-center pb-12 absolute top-1/3 left-1/2 -translate-y-1/2 -translate-x-1/2">
            About Benchmark Tutoring...
          </div>
          <div className="hidden sm:block text-lg text-black text-center absolute top-1/2 left-1/2 -translate-x-1/2">
          We aim to guide our community to excellence at Benchmark Tutoring and empower the youth to achieve outstanding results. As a result of our comprehensively structured and easy-to-understand courses, our students often come to enjoy the subject as more than a compulsory commitment.
              In all of our education services, our core three values are always upheld to the highest standard.
          </div>
          <div className="block sm:hidden w-10/12 text-md text-black text-center absolute top-1/2 left-1/2 -translate-y-1/4 -translate-x-1/2">
          We aim to guide our community to excellence at Benchmark Tutoring and empower the youth to achieve outstanding results. As a result of our comprehensively structured and easy-to-understand courses, our students often come to enjoy the subject as more than a compulsory commitment.
              In all of our education services, our core three values are always upheld to the highest standard.
          </div>
        </div>
      </div>
      <div ref={ref0} className={`flex justify-center pb-32 transition-opacity ease-in duration-1000 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
        <div className="w-10/12 sm:w-2/3 bg-white grid sm:flex justify-center align-top">
          <div className="w-full pb-24 sm:p-5">
            <div className="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
              </svg>
            </div>
            <div className="text-3xl text-center pt-12 p-5">
              Accountability
            </div>
            <div className="text-md sm:text-lg text-center">
              Our tutors always put 100% of our effort into our work and will undoubtedly help any student achieve better grades. Parents can expect constant feedback with tangible steps to improvement, and any requests will be implemented into lessons. Our tutors will be readily available for 24/7 online feedback, always looking to go beyond expectations.
            </div>
          </div>
          <div className="w-full p-5">
            <div className="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
              </svg>
            </div>
            <div className="text-3xl text-center pt-12 p-5">
              Care
            </div>
            <div className="text-md sm:text-lg text-center">
              All tutors are high-achievers that know the struggles of being a student. Our tutors are willing to be there as a mentor, such as by assisting students by providing advice on VCE subject selection. We teach patiently and compassionately, and will do anything it takes to give you the best shot. Countless hours have gone into providing our students with the best courses out there.
            </div>
          </div>
          <div className="w-full p-5">
            <div className="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
              </svg>
            </div>
            <div className="text-3xl text-center pt-12 p-5">
              Quality
            </div>
            <div className="text-md sm:text-lg text-center">
              All tutors are trained for their specific subject. They have been taught how to structure their lessons, how to best provide value to students, and overall how to be good tutors in general. The Co-Founders recognised that comprehensive and subject-tailored training was something that was often missing in the tutoring industry, and decided to make a change. Our tutors have access to Benchmark Tutoring resources to use for every subject that will aid their teaching and support our students.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About