import './App.css';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Navbar from './components/Navbar';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Faqs from './pages/Faqs';
import Media from './pages/Media';
import Resources from './pages/Resources';
import Japanese from './pages/Japanese';
import English from './pages/English';
import Maths from './pages/Maths';
import Pricing from './pages/Pricing';
import Who from './pages/Who';
import HowAtar from './pages-media/HowAtar';
import JapaneseScaling from './pages-media/JapaneseScaling';
import Privacy from './pages/Privacy';

function App() {
  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="media" element={<Media />} />
            <Route path="resources" element={<Resources />} />
            <Route path="vce-japanese-tutoring" element={<Japanese />} />
            <Route path="vce-maths-tutoring" element={<Maths />} />
            <Route path="vce-english-tutoring" element={<English />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="who-are-we" element={<Who />} />
            <Route path="media/how-is-the-atar-calculated" element={<HowAtar />} />
            <Route path="media/vce-japanese-scaling" element={<JapaneseScaling />} />
            <Route path="privacy" element={<Privacy />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
