export const posts = [
    {
      title: "How is the ATAR calculated? - VCE",
      description:
        "As we can see from the name, the ATAR is a ranking that is used for university (and other tertiary institutions) admission. ATAR stands for: Australian Tertiary Admission Rank. As we can see...",
      path: "how-is-the-atar-calculated"
    },
    {
      title: "How much does VCE Japanese scale up?",
      description:
        "We've all heard that scaling is not a free ticket to a higher study score. After all, scaling is used to account for the competitiveness of that subject's cohort. However, there is one exception...",
      path: "vce-japanese-scaling"
    },
    
  ];