import React, { useState } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";

export default function QuestionBox({ title, description, path }) {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <>
      <div className={`${showAnswer && "border-[#8FCB9B]"} border-2 border-[#EAE6E5] rounded-xl bg-white`}>
        <div className="flex items-center justify-between p-4 lg:p-6">
          <h2
            className="cursor-pointer"
            onClick={() => setShowAnswer(!showAnswer)}
          >
            {title}
          </h2>
          <ul>
            {!showAnswer && (
              <li>
                <button onClick={() => setShowAnswer(true)}>
                  <BsPlusLg />
                </button>
              </li>
            )}
            {showAnswer && (
              <li>
                <button onClick={() => setShowAnswer(false)}>
                  <BiMinus />
                </button>
              </li>
            )}
          </ul>
        </div>

        <div
          className={`${showAnswer && "border-t-2 border-[#8FCB9B] p-4 lg:p-6"}`}
        >
          {showAnswer && <div className="grid"><p className="mb-3">{description}</p>
            <div className="flex justify-end">
              <a className="flex border justify-center items-center border-black rounded-xl p-1 hover:border-[#8FCB9B] duration-500 cursor-pointer px-2" href={path} download>Download</a>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
}