import React from 'react'
import { useRef } from 'react';
import { useIsVisible } from '../components/Visible';
import grammar from "../assets/grammar.png";
import speaking from "../assets/speaking.png";
import listening from "../assets/listening.png";
import writing from "../assets/writing.png";

import { Helmet } from 'react-helmet';

const TITLE = 'VCE Japanese Tutoring - Benchmark Tutoring';


const Japanese = () => {

  const ref0 = useRef();
  const isVisible0 = useIsVisible(ref0);

  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-[#EAE6E5]">
        <div className="bg-[#8FCB9B] grid justify-center relative w-full overflow-hidden">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g clip-path="url(&quot;#SvgjsClipPath1008&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(91, 146, 121, 1)"></rect>
                <circle r="93.335" cx="62.82" cy="80.55" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="93.335" cx="325.31" cy="323.29" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="93.335" cx="811.46" cy="700.82" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="93.335" cx="1209.7" cy="1124.39" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="93.335" cx="1553.39" cy="1412.4" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="93.335" cx="1918.41" cy="1837.03" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="112.97" cx="1187.76" cy="232.86" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="160.07" cx="1254.54" cy="484.81" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="99.82" cx="2201.33" cy="381.26" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="152.18" cx="1719.1" cy="203.27" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="154.885" cx="1783.73" cy="443.72" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="120.765" cx="1716.76" cy="263.04" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="57.15" cx="364.62" cy="81.97" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="96.78" cx="121.3" cy="452.41" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="167.085" cx="78.14" cy="122.64" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="123.325" cx="2199.74" cy="207.46" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="113.905" cx="223.32" cy="319.9" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="96.065" cx="746.79" cy="86.43" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="138.37" cx="2463.43" cy="118.83" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="79.77" cx="1493.54" cy="486.24" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="82.285" cx="1582.11" cy="6.11" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="113.26" cx="1056.07" cy="407.09" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
                <circle r="154.03" cx="662.87" cy="263.92" stroke="#8fcb9b" stroke-opacity="1" stroke-width="2"></circle>
            </g>
            <defs>
                <clipPath id="SvgjsClipPath1008">
                    <rect width="2500" height="560" x="0" y="0"></rect>
                </clipPath>
            </defs>
          </svg>
          <h1 className="hidden font-serif sm:block text-white absolute text-5xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            VCE Japanese Tutoring
          </h1>
          <div className="visible font-serif sm:hidden text-white absolute text-center leading-normal text-4xl top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
            VCE Japanese Tutoring
          </div>
          <div className="hidden sm:block text-white absolute text-center text-xl top-2/3 left-1/2 -translate-x-1/2 -translate-y-2/3">
            In our VCE Japanese Tutoring course guide at Benchmark, we focus on the four aspects that exams and SACs are based on: grammar, writing, speaking and listening.
          </div>
          <div className="visible sm:hidden w-5/6 text-white absolute text-center text-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/6">
            In our VCE Japanese Tutoring course guide at Benchmark, we focus on the four aspects that exams and SACs are based on: grammar, writing, speaking and listening.
          </div>
        </div>
        <div ref={ref0} className={`pb-24 transition-opacity ease-in duration-1000 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
          <div className="flex items-center justify-center p-5">
            <div className="grid sm:hidden grid-cols-4 w-3/4 gap-4">
              <div className="text-center">Grammar</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                </svg>
              </div>
              <div className="text-center">Writing</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </div>
              <div className="text-center">Speaking</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
                </svg>
              </div>
              <div className="text-center">Listening</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>
              </div>
              </div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <h2 className="text-xl p-3 w-1/12 text-center">Grammar</h2>
            <h2 className="text-xl p-3 w-1/12 text-center">Writing</h2>
            <h2 className="text-xl p-3 w-1/12 text-center">Speaking</h2>
            <h2 className="text-xl p-3 w-1/12 text-center">Listening</h2>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="py-16 text-md sm:text-xl w-10/12 sm:w-1/2 text-center">
              We also place a strong emphasis on pursuing students' interests within Japanese culture and exploring the language independent of VCE studies.
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex justify-center items-center">
        <div className="w-1/2 grid grid-cols-2 gap-16 py-24">
          <div ref={ref1} className={`transition-opacity ease-in duration-1000 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Grammar</div>
            <div className="py-3 leading-loose">Follow our teaching PowerPoints and worksheets for every required VCE grammar point, specifically created for our Benchmark students! <br></br>Our PowerPoints are clear and concise with multiple examples while our worksheets allow students to fully understand the material at hand.</div>
          </div>
          <div ref={ref1} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden "><img className="" src={grammar} alt="Grammar example"></img></div>
          </div>
          <div ref={ref2} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden"><img className="" src={writing} alt="Writing example"></img></div>
          </div>
          <div ref={ref2} className={`transition-opacity ease-in duration-1000 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Writing</div>
            <div className="py-3 leading-loose">Our comprehensive writing booklet will teach you exactly HOW to structure your essays for each topic genre, the layout of your essay (discourse form) and many useful expressions to include in your essays. Students will naturally become accustomed to skilful vocabulary usage and kanji accuracy through our lessons. We understand that VCE students need all the support they can get, so we’re also happy to mark unlimited essays anytime outside of lessons as well. Just send them through!</div>
          </div>
          <div ref={ref3} className={`transition-opacity ease-in duration-1000 ${isVisible3 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Speaking</div>
            <div className="py-3 leading-loose">With both co-founders scoring full marks (100/100) on their oral exams, our results-proven three-step study process will surely maximise your potential marks for speaking. We’ll provide you with the templates you need to draft your speaking script, and we’ll help you fill it in along the way. Included in our lessons are numerous mock oral practices that mimic the final exam to give you the best preparation possible!</div>
          </div>
          <div ref={ref3} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible3 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden"><img className="" src={speaking} alt="Speaking example"></img></div>
          </div>
          <div ref={ref4} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible4 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden"><img className="" src={listening} alt="Listening example"></img></div>
          </div> 
          <div ref={ref4} className={`transition-opacity ease-in duration-1000 ${isVisible4 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Listening</div>
            <div className="py-3 leading-loose">Learn the most efficient note-taking techniques for listening tasks, and how to turn that into a full-mark answer. At our disposal, we have access to almost 200 listening tasks so rest assured that there will always be practice available. </div>
          </div>
        </div>
      </div>
      <div className="flex sm:hidden justify-center items-center">
        <div className="w-9/12 grid-cols-2 gap-16 py-24">
          <div ref={ref5} className={`transition-opacity ease-in duration-1000 ${isVisible5 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Grammar</div>
            <div className="py-12">Follow our teaching PowerPoints and worksheets for every required VCE grammar point, specifically created for our Benchmark students! <br></br>Our PowerPoints are clear and concise with multiple examples while our worksheets allow students to fully understand the material at hand.</div>
          </div>
          <div ref={ref5} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible5 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden "><img className="" src={grammar} alt="Grammar example"></img></div>
          </div>
            <div ref={ref6} className={`transition-opacity ease-in duration-1000 ${isVisible6 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Writing</div>
            <div className="py-12">Our comprehensive writing booklet will teach you exactly HOW to structure your essays for each topic genre, the layout of your essay (discourse form) and many useful expressions to include in your essays. Students will naturally become accustomed to skilful vocabulary usage and kanji accuracy through our lessons. We understand that VCE students need all the support they can get, so we’re also happy to mark unlimited essays anytime outside of lessons as well. Just send them through!</div>
          </div>
          <div ref={ref6} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible6 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden"><img className="" src={writing} alt="Writing example"></img></div>
          </div>
            <div ref={ref7} className={`transition-opacity ease-in duration-1000 ${isVisible7 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Speaking</div>
            <div className="py-12">With both co-founders scoring full marks (100/100) on their oral exams, our results-proven three-step study process will surely maximise your potential marks for speaking. We’ll provide you with the templates you need to draft your speaking script, and we’ll help you fill it in along the way. Included in our lessons are numerous mock oral practices that mimic the final exam to give you the best preparation possible!</div>
          </div>
          <div ref={ref7} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible7 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden"><img className="" src={speaking} alt="Speaking example"></img></div>
          </div>
            <div ref={ref8} className={`transition-opacity ease-in duration-1000 ${isVisible8 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Listening</div>
            <div className="py-12">Learn the most efficient note-taking techniques for listening tasks, and how to turn that into a full-mark answer. At our disposal, we have access to almost 200 listening tasks so rest assured that there will always be practice available. </div>
          </div>
          <div ref={ref8} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible8 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden"><img className="" src={listening} alt="Listening example"></img></div>
          </div> 
        </div>
      </div>           
    </> 
  )
}

export default Japanese