import React from 'react'
import { useState } from 'react';
import { questions } from '../assets/questions';
import QuestionBox from '../components/QuestionBox';
import { useRef } from 'react';
import { useIsVisible } from '../components/Visible';

import { Helmet } from 'react-helmet';

const TITLE = 'FAQs - Benchmark Tutoring';

const Faqs = () => {

  const [cards] = useState(questions);

  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-white flex items-center justify-center">
        <div className="w-full bg-[#8FCB9B] mb-24 relative overflow-hidden">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g mask="url(&quot;#SvgjsMask1004&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(91, 146, 121, 1)"></rect>
                <path d="M1157.68 621.83C1394.89 595.71 1536.11 78.77 1940.45 75.48 2344.8 72.19 2524.43 214.93 2723.23 215.48" stroke="rgba(143, 203, 155, 1)" stroke-width="2"></path>
                <path d="M1476.37 660.7C1658.63 660.22 1822.06 538.06 2194.39 534.48 2566.71 530.9 2720.78 270.04 2912.4 265.68" stroke="rgba(143, 203, 155, 1)" stroke-width="2"></path>
                <path d="M838.87 587.29C1091.5 577.1 1294.85 162.09 1756.34 161.79 2217.83 161.49 2437.71 384.18 2673.81 385.79" stroke="rgba(143, 203, 155, 1)" stroke-width="2"></path>
                <path d="M593.43 662.87C793.51 660.01 972.57 421.55 1356.51 421.28 1740.45 421.01 1738.05 491.28 2119.59 491.28 2501.13 491.28 2691.1 421.35 2882.67 421.28" stroke="rgba(143, 203, 155, 1)" stroke-width="2"></path>
                <path d="M358.14 577.86C552.67 576.58 735.38 395.86 1114.68 395.78 1493.99 395.7 1492.96 465.78 1871.23 465.78 2249.5 465.78 2437.83 395.85 2627.77 395.78" stroke="rgba(143, 203, 155, 1)" stroke-width="2"></path>
            </g>
            <defs>
                <mask id="SvgjsMask1004">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
            </defs>
          </svg>
          <h1 className="hidden font-serif sm:block text-5xl text-white text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2">
            Frequently Asked Questions
          </h1>
          <div className="block font-serif sm:hidden text-4xl w-9/12 text-white text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2">
            Frequently Asked Questions
          </div>
          <div className="hidden sm:block text-lg text-white text-center absolute top-1/2 left-1/2 -translate-x-1/2">
            If you have any further questions, please don't hesitate to contact us via Facebook or email.
          </div>
          <div className="block sm:hidden w-10/12 text-md text-white text-center absolute top-1/2 left-1/2 -translate-x-1/2">
            If you have any further questions, please don't hesitate to contact us via Facebook or email.
          </div>
        </div>
      </div>
      <div ref={ref1} className={`flex items-center justify-center transition-opacity ease-in duration-1000 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
        <div className="w-10/12 sm:w-5/12 grid grid-cols-1 gap-8">
            {cards.map((card, index) => (
              <QuestionBox {...card} key={index} />
            ))}
        </div>
      </div>
      <div className="py-32 px-5 sm:px-32">
        <div className="text-lg text-center">
          Get into contact with us <a className="text-[#8FCB9B] font-bold" href="https://www.facebook.com/benchmark.tutoring">here</a> for any further questions!
        </div>
      </div>
    </>
  )
}

export default Faqs