import React from 'react'
import { Outlet, Link } from 'react-router-dom';
import logo from "../assets/Benchmark_Banner.jpg";
import {useState, useEffect} from 'react'

const Navbar = () => {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true)

    const handleScroll = () => {
        const currentScrollPos = window.scrollY

        if(currentScrollPos > prevScrollPos){
            setVisible(false)
            setMenuOpen(false);
            setDropdownOpen1(false);
            setDropdownOpen2(false);
        }else{
            setVisible(true)
        }

        setPrevScrollPos(currentScrollPos)
    }

    useEffect( () => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    })


    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (event) => {
      if (event) {
        //event.preventDefault();
        setMenuOpen(!menuOpen);
        setDropdownOpen1(false);
        setDropdownOpen2(false);
        setDropdownOpen4(false);
      }
    }

    const closeMenu = (event) => {
        if (event) {
          //event.preventDefault();
          setMenuOpen(false);
          setDropdownOpen1(false);
          setDropdownOpen2(false);
          setDropdownOpen4(false);
        }
      }

    const [dropdownOpen1, setDropdownOpen1] = useState(false);

    const toggleDropdown1 = (event) => {
        if (event) {
          //event.preventDefault();
          setDropdownOpen1(!dropdownOpen1);
        }
      }
    const [dropdownOpen2, setDropdownOpen2] = useState(false);

    const toggleDropdown2 = (event) => {
        if (event) {
          //event.preventDefault();
          setDropdownOpen4(false);
          setDropdownOpen2(!dropdownOpen2);
          
        }
      }

      const [dropdownOpen3, setDropdownOpen3] = useState(false);

      const toggleDropdown3 = (event) => {
          if (event) {
            //event.preventDefault();
            setDropdownOpen3(!dropdownOpen3);
          }
        }
      const [dropdownOpen4, setDropdownOpen4] = useState(false);
  
      const toggleDropdown4 = (event) => {
          if (event) {
            //event.preventDefault();
            setDropdownOpen4(!dropdownOpen4);
            setDropdownOpen2(false);
          }
        }

    return (
        <div className="relative z-50">
            <div className={`bg-white grid sm:flex p-3 border-b-2 border-black sticky z-50 ${visible ? 'top-0' : ''} `} >
                <div className="hidden z-10 sm:block w-1/3 bg-white">

                </div>
                <div className="px-2 z-10 flex justify-between sm:w-1/3 bg-white sm:grid sm:justify-items-center cursor-pointer">
                    <span onClick={closeMenu} className="sm:flex justify-center items-center">
                        <Link to="/" onClick={() => window.scrollTo(0, 0)} className="w-6/12 flex justify-center items-center">
                            <img src={logo} alt="Logo"></img>
                        </Link>
                    </span>
                    <button onClick={toggleMenu} className="px-2 sm:hidden">
                        <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor" 
                        className={(menuOpen ? 'opacity-100' : 'opacity-100') + " block transition ease-in duration-300 w-8 h-8"}>
                            <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
                <nav className="hidden overflow-auto z-10 sm:flex sm:w-1/3 bg-white sm:justify-end">
                    <ul className="list-none justify-start items-center sm:flex">
                        <li onMouseEnter={toggleDropdown1} onMouseLeave={toggleDropdown1} className="overflow-hidden p-3 bg-white">
                            <Link to="/" onClick={() => window.scrollTo(0, 0)} className="bg-white hover:text-[#8FCB9B] duration-500 cursor-pointer">Subjects</Link>
                            <div className={(dropdownOpen1 ? 'opacity-100' : 'opacity-0 -left-full overflow-hidden') + " absolute transition ease-in duration-100 bg-white grid border border-black rounded-xl mb-24 mt-3"}>
                                <Link to="/resources " onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Free Resources</Link>
                                <Link to="/vce-english-tutoring" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">English</Link>
                                <Link to="/vce-maths-tutoring" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Mathematics</Link>
                                <Link to="/vce-japanese-tutoring" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Japanese</Link>
                                <div className="p-2 px-3">and more...</div>
                            </div>
                        </li>
                        <li onMouseEnter={toggleDropdown3} onMouseLeave={toggleDropdown3} className="overflow-hidden p-3 bg-white">
                            <Link to="/about" onClick={() => window.scrollTo(0, 0)} className="bg-white hover:text-[#8FCB9B] duration-500 cursor-pointer">About Us</Link>
                            <div className={(dropdownOpen3 ? 'opacity-100' : 'opacity-0 -left-full overflow-hidden') + " absolute transition ease-in duration-100 bg-white grid border border-black rounded-xl mb-24 mt-3"}>
                                <Link to="/about" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">About Benchmark</Link>
                                <Link to="/who-are-we" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Who Are We?</Link>
                                <Link to="/faqs" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">FAQs</Link>
                                <Link to="/pricing" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Pricing</Link>
                                <Link to="/media" onClick={() => window.scrollTo(0, 0)} className="p-2 px-3 hover:text-[#8FCB9B] duration-500">Media</Link>
                            </div>
                        </li>
                        <li className="p-3 hover:text-[#8FCB9B] duration-500">
                            <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className="">
                    <nav className={(menuOpen ? 'opacity-100' : 'opacity-0 -left-full overflow-hidden') + "  sm:hidden absolute transition ease-in duration-200 left-0 right-0 z-0 bg-white border-b border-black"}>
                        <ul className="list-none justify-start top-full items-center sm:flex">
                            <li onClick={toggleDropdown2} className="p-3 bg-white">
                                <div className={(dropdownOpen2 ? 'font-semibold' : '') + " overflow-hidden bg-white hover:text-[#8FCB9B] duration-500"}>Subjects</div>
                                    <div className={(dropdownOpen2 ? 'opacity-100 left-0' : 'opacity-0 -left-full overflow-hidden') + " mt-2 absolute transition ease-in duration-200 bg-white grid w-screen"}>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/resources" onClick={() => window.scrollTo(0, 0)}>Free Resources</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/vce-english-tutoring" onClick={() => window.scrollTo(0, 0)}>English</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/vce-maths-tutoring" onClick={() => window.scrollTo(0, 0)}>Mathematics</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/vce-japanese-tutoring" onClick={() => window.scrollTo(0, 0)}>Japanese</Link></span>
                                    <div className="p-2 pl-5 px-3">and more...</div>
                                </div>
                            </li>
                            <li onClick={toggleDropdown4} className="p-3 bg-white">
                                <div className={(dropdownOpen4 ? 'font-semibold' : '') + " bg-white hover:text-[#8FCB9B] duration-500 overflow-hidden"}>About Us</div>
                                    <div className={(dropdownOpen4 ? 'opacity-100 left-0' : 'opacity-0 -left-full overflow-hidden') + " mt-2 absolute transition ease-in duration-200 bg-white grid w-screen"}>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/about" onClick={() => window.scrollTo(0, 0)}>About Benchmark</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/who-are-we" onClick={() => window.scrollTo(0, 0)}>Who Are We?</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/faqs" onClick={() => window.scrollTo(0, 0)}>FAQs</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/pricing" onClick={() => window.scrollTo(0, 0)}>Pricing</Link></span>
                                        <span onClick={toggleMenu} className="p-2 pl-5 px-3 hover:text-[#8FCB9B] duration-500"><Link to="/media" onClick={() => window.scrollTo(0, 0)}>Media</Link></span>
                                </div>
                            </li>
                            <li onClick={toggleMenu} className="p-3 hover:text-[#8FCB9B] duration-500">
                                <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            
            
          <div onClick={closeMenu}><Outlet /></div>
          
          <>
            <div className="bg-[#8F8073]">   
                <div className="flex items-center justify-center pt-6 p-2">
                    <a href="https://www.tiktok.com/@benchmark.tutoring">
                        <svg className="mx-3" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="3vh" height="3vh">
                            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"/>
                        </svg>
                    </a>
                    <a href="https://www.facebook.com/benchmark.tutoring">
                        <svg className="mx-3" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="3vh" height="3vh">
                            <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/benchmark.tutoring/">
                        <svg className="mx-3" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="3vh" height="3vh">
                            <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
                        </svg>
                    </a>
                </div>
                <div className="text-xs grid justify-center items-center pb-6 p-2">
                    <div className="py-1 text-center">Benchmark Tutoring</div>
                    <div className="py-1 text-center">ABN: 39611479875</div>
                </div>
            </div>
          </>
        </div>
      )
}

export default Navbar
