export const resources = [
    {
      title: "VCE Japanese Reading Task - Tokyo Olympics",
      description:
        "Our free reading revision resource “Tokyo Olympics” is here for download! This resource is relevant to the new (2020) study design and includes reading questions and an essay task based on that text to emulate Section 2 Part B of the exam.",
      path: "VCE-JP_Reading_Task.pdf"
    },
    {
        title: "VCE Japanese Reading Task - Suggested Answers",
        description:
          "Answers to VCE Japanese Reading Task - Tokyo Olympics.",
        path: "VCE-JP_Reading_Task_ANS.pdf"
    },
    {
        title: "VCE Japanese Writing Task - Example Essay",
        description:
          "Our free writing resource is here for download! This resource is a high-scoring example essay based on a question type that may come up in section 3 of the exam.",
        path: "VCE-JP_Sample_Essay.pdf"
    },
  ];