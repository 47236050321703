import React from 'react'
import Carousel from '../components/Carousel';
import flowchart from "../assets/flowchart-cursor.jpg";
import vertical1 from "../assets/vertical1.png";
import vertical2 from "../assets/vertical2.png";
import { useIsVisible } from '../components/Visible';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import noNonsense from '../assets/no-nonsense-tutoring.jpg'
import whatSets from '../assets/what-sets-benchmark-apart.jpg'

const Home = () => {

  let reviews = [
    ["Stella was an amazing tutor for VCE Japanese this year. She was very friendly and created a comfortable environment from the very first lesson, which I was extremely grateful for. Throughout the year, we worked together to analyse my weaknesses, and she helped me to strategically break these weaknesses down into simple and clear steps. Stella was also very knowledgeable, and was able to answer all of my questions regarding various topics, even those outside the VCE syllabus. As she had just finished VCE Japanese, she was very well versed in the technicalities and common mistakes made during exams and SACs. Overall, Stella definitely helped me grasp a better understanding of Japanese as a whole this year, and this helped me to feel more confident going into my SACs and exams.","Asher, 2023"],
    ["In Year 11, I was really struggling with my Japanese and I honestly lost my motivation and hope for the subject. Come the next year, a friend told me about Mark and his tutoring. Since the very start, he looked after me and gradually gave me the confidence I needed for the subject. He made personalised work sheets at the start of the year and then prepared me thoroughly for all my SACs. He had tips and advice that even shocked my teacher and to this day I am forever grateful how caring he was for me. He was always considerate of my schedule and looked after me like an older brother and made sure I wasn’t stressed. He gave study tips, life lessons that I’ll hold for life, and his resources were simply ones you would never find elsewhere. The way Mark taught made everything so much easier to understand and I genuinely looked forward to every class each week. Mark will always be one of the greatest teachers and tutors I’ve had","Ryan, 2022"],
    ["Working with Luna throughout my unit 3/4 Japanese was the most helpful and enjoyable experience I had while learning the language. Luna, not only was able to fine tune my language knowledge I already had, she was able to expand and teach in very helpful ways that made learning and memorising so much easier. Luna’s experience as a past student of VCE Japanese exam setting helped me with advice and practice especially with the oral section of the subject. She is an excellent tutor.","Naadirah, 2023"],
    ["Mark has been an English tutor for my son for the past 2 years. He has helped my son thrive in his studies with his caring manner and willingness to help my son in anyway to ensure he succeeds. Mark is incredibly reliable and is always available for my son, he has certainly gone above and beyond to provide my son with an amazing tutoring experience. He has excellent time management as he is always on time for his tutoring sessions and is a good communicator. Overall, Mark is an exceptional tutor and because of his professional and caring nature, my son has been able to advance in his studies.","Parent, 2022"],
    ["I found Justin’s maths lessons really engaging and constructive. His approach to teaching me a chapter or certain topics worked well and his strategy to help me prepare for SACs and exams really supported me in consolidating the information and applying it to various questions. Overall, the lessons with Justin were really engaging - he always provided constructive feedback and information on my approach to the subject as well as supporting me throughout the year to continuously do well in the examinations.","Yousif, 2023"],
    ["Studying with Benchmark this year has been enjoyable and engaging, helping me form a profound passion for the language that I would not have found otherwise. Through the organised and well-coordinated lessons, I could see the countless hours of preparation that my tutor put in beforehand. It was pleasing to see that Benchmark was able to lay out such a structured course that actually provided me direction in learning a VCE language. Coming up to any SACs and the exam, my tutor would readily provide me with detailed feedback on any practice essay I submitted, which I found significantly helped me. Overall, I’m extremely glad that I chose tutoring with Benchmark.","Verena, 2021"],
    ["Akira is an attentive, knowledgeable and committed Japanese tutor that has helped me to strengthen my Japanese skills throughout the course of the year. It was particularly useful to have a tutor that has not long graduated high school, and hence understands the demands of VCE Japanese, and therefore I was able to ask him any questions about the language itself and the assessment guidelines (exams, SACS, speaking etiquette). Furthermore, Akira makes Japanese enjoyable to learn, and remained patient throughout my learning, aiding with my struggles in grammar patterns and particles, along with assisting me greatly for the speaking portion of my exam. I particularly enjoyed that the structure of the lesson was up to you - Akira would format his lessons to specifically target my areas of weakness and to address my questions. I am extremely happy with the help Akira has provided me and I recommend him without hesitation.","Jen, 2022"],    
  ];

  const ref0 = useRef();
  const isVisible0 = useIsVisible(ref0);

  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-[#EAE6E5] ">
        <div className="bg-[#8FCB9B] grid justify-center relative w-full overflow-hidden mb-6 sm:mb-5">
        <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g mask="url(&quot;#SvgjsMask1026&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(143, 203, 155, 1)"></rect>
                <path d="M2541.666666666666 560L0 560 L0 465.4Q75.58 415.98, 125 491.56Q238.49 396.71, 333.3333333333333 510.2Q366.11 417.98, 458.3333333333333 450.76Q585.3 369.39, 666.6666666666666 496.35Q736.54 441.23, 791.6666666666666 511.1Q832.44 426.88, 916.6666666666666 467.65Q956.45 382.43, 1041.6666666666665 422.21Q1119.58 375.12, 1166.6666666666665 453.03Q1244.88 406.24, 1291.6666666666665 484.45Q1412.48 396.93, 1499.9999999999998 517.74Q1521.13 413.87, 1624.9999999999998 435Q1748.75 350.41, 1833.333333333333 474.16Q1958.53 391.03, 2041.6666666666663 516.23Q2092.2 441.76, 2166.666666666666 492.29Q2203.52 404.14, 2291.666666666666 440.99Q2374.28 398.6, 2416.666666666666 481.21Q2472.93 412.48, 2541.666666666666 468.74z" fill="rgba(255, 255, 255, 1)"></path>
                <path d="M2625 560L0 560 L0 565.24Q87.6 444.51, 208.33333333333331 532.11Q263.38 462.15, 333.3333333333333 517.2Q424.4 483.27, 458.3333333333333 574.34Q505.9 496.91, 583.3333333333333 544.47Q655.33 491.47, 708.3333333333333 563.46Q748.58 478.71, 833.3333333333333 518.96Q950.8 428.1, 1041.6666666666665 545.57Q1121.25 500.15, 1166.6666666666665 579.73Q1226.13 430.86, 1374.9999999999998 490.32Q1458.74 449.06, 1499.9999999999998 532.8Q1571.74 479.54, 1624.9999999999998 551.28Q1658.07 459.36, 1749.9999999999998 492.43Q1832.77 450.21, 1874.9999999999998 532.98Q1949.74 482.72, 1999.9999999999998 557.46Q2109.59 458.71, 2208.333333333333 568.3Q2276.53 428.16, 2416.6666666666665 496.36Q2531.03 402.39, 2625 516.76z" fill="rgba(91, 146, 121, 1)"></path>
            </g>
            <defs>
                <mask id="SvgjsMask1026">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
            </defs>
          </svg>
          <h1 className="hidden sm:block font-serif text-black absolute md:w-full text-center text-4xl md:text-5xl top-1/3 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-3/4 -translate-y-1/4">Benchmark Tutoring - VCE Tutors</h1>
          <div className="visible sm:hidden font-serif text-black absolute text-center md:w-full  leading-normal text-3xl top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Benchmark Tutoring VCE Tutors
          </div>
          <div className="hidden sm:block text-black absolute text-center w-8/12 text-lg md:text-xl top-2/3 left-1/2 -translate-x-1/2 -translate-y-2/3">
            Promoting and supporting students' education through patient, targeted and relevant tutoring. <br></br> 2024 Bookings for a Free Trial Lesson are now open. Learn from our caring and capable tutors today!
          </div>
          <div className="visible sm:hidden w-5/6 text-black absolute text-center text-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/6">
            Promoting and supporting students' education through patient, targeted and relevant tutoring. <br></br> 2024 Bookings for a Free Trial Lesson are now open. Learn from our caring and capable tutors today!
          </div>
          <div className="absolute text-center text-xl top-3/4 left-1/2 md:translate-y-1/2 -translate-x-1/2 translate-y-2/3 sm:-translate-y-1/4">
            <div className="grid bg-red-300 hover:bg-red-400 justify-center w-full border-2 border-black rounded-lg">
              <Link to="/contact" onClick={() => window.scrollTo(0, 0)} className="animate-bounce mx-6 mt-2">Free Trial</Link>
            </div>
          </div>
        </div>
        <div ref={ref0} className={`pb-24 transition-opacity ease-in duration-1000 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
          <div className="flex justify-center items-center">
            <div className="p-5 sm:w-2/3 sm:text-3xl text-center sm:p-10">
              Our focus starts with our <span className="font-bold">tutors'</span> development and satisfaction, ensuring you (your child) is getting the expertise and attention to thrive in each of their subjects.
            </div>
          </div>
          <div className="flex items-center justify-center p-5">
            <div className="grid sm:hidden grid-cols-1 gap-6">
              <hr className="h-0.5 bg-black"></hr>
              <div className="text-xl text-center">Carefully Selected Through a Three-part Application Process</div>
                <div className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </div>
              <div className="text-md p-3 px-3 pt-5 text-center">
                All of our VCE tutors and Pre-VCE tutors are carefully selected based not only on their academic merit and subject knowledge, but also their care, compassion and empathy.
              </div>
              <hr className="h-0.5 bg-black"></hr>
              <div className="text-xl text-center">Teaching Style & Personality Coupled with Academic Merit</div>
                <div className="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                  </svg>
                </div>
              <div className="text-md p-3 px-3 pt-5 text-center">
                Our selection interview coupled with our training and guidance, ensures that all of our tutors can break concepts down and are clear and concise in their teaching.
              </div>
              <hr className="h-0.5 bg-black"></hr>
              <div className="text-xl text-center">A Focus on Training, Guidance & Resources</div>
                <div className="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                  </svg>
                </div>
              <div className="text-md p-3 px-3 pt-5 text-center pb-0">
                We know it takes more to be a tutor than just academic results. Behind our tutors is an experienced support team to keep all of our tutor's skills and classes top notch. 
              </div>
              </div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="text-xl p-3 px-5 w-1/5 text-center">Carefully Selected Through a Three-part Application Process</div>
            <div className="text-xl p-3 px-5 w-1/5 text-center">Teaching Style & Personality Coupled with Academic Merit</div>
            <div className="text-xl p-3 px-5 w-1/5 text-center">A Focus on Training, Guidance & Resources</div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="flex w-1/5 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
              </svg>
            </div>
            <div className="flex w-1/5 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
              </svg>
            </div>
            <div className="flex w-1/5 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
              </svg>
            </div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="text-lg p-3 px-3 pt-5 w-1/5 text-center">
              All of our VCE tutors and Pre-VCE tutors are carefully selected based not only on their academic merit and subject knowledge, but also their care, compassion and empathy.
            </div>
            <div className="text-lg p-3 px-3 pt-5 w-1/5 text-center">
              Our selection interview coupled with our training and guidance, ensures that all of our tutors can break concepts down and are clear and concise in their teaching.
            </div>
            <div className="text-lg p-3 px-3 pt-5 w-1/5 text-center">
              We know it takes more to be a tutor than just academic results. Behind our tutors is an experienced support team to keep all of our tutor's skills and classes top notch. 
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="hidden sm:flex justify-center items-center">
          <div className="w-7/12 grid grid-cols-2 gap-12 py-24">
            <div className={`p-3 transition-opacity ease-in duration-1000`}>
              <h2 className="text-3xl pb-3">What sets Benchmark's tutors apart?</h2>
              <div className="py-3">✅ Qualified beyond their VCE scores<br /><br />✅ Trained and have a desire to perpetually improve<br /><br />✅ Role models with their own wisdom and expertise<br /><br />✅ Highly adaptive, empathetic and puts the student first<br /></div>
            </div>
            <div className={`p-3 transition-opacity ease-in duration-1000 grid justify-start items-center py-4`}>
              <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] bg-[#d5e8d9] rounded-2xl overflow-hidden "><img className="" src={whatSets} alt="Benchmark's VCE Tutors Melbourne"></img></div>
            </div>
            <div className={`p-3 transition-opacity ease-in duration-1000 grid justify-start items-center py-4`}>
              <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] bg-[#d5e8d9] rounded-2xl overflow-hidden"><img className="" src={noNonsense} alt="VCE Tutoring at Benchmark"></img></div>
            </div>
            <div className={`p-3 transition-opacity ease-in duration-1000`}>
              <h3 className="text-3xl pb-3">No nonsense, smooth processes</h3>
              <div className="py-3">✅ No upfront fees or hidden costs<br /><br />✅ Automatic invoices and payments<br /><br />✅ Lesson schedules and notes all in the same place<br /><br />✅ Free trial to ease any concerns<br /></div>
            </div>
          </div>
        </div>
        <div className="flex sm:hidden justify-center items-center">
          <div className="w-9/12 grid-cols-2 gap-16 py-24">
            <div className={`transition-opacity ease-in duration-1000`}>
              <div className="text-2xl text-center">What sets Benchmark's tutors apart?</div>
              <div className="py-12">✅ Qualified beyond their VCE scores<br /><br />✅ Trained and have a desire to perpetually improve<br /><br />✅ Role models with their own wisdom and expertise<br /><br />✅ Highly adaptive, empathetic and puts the student first<br /></div>
            </div>
            <div className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12`}>
              <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden "><img className="" src={whatSets} alt="Benchmark's VCE Tutors Melbourne"></img></div>
            </div>
              <div className={`transition-opacity ease-in duration-1000`}>
              <div className="text-2xl text-center">Let tutors focus on tutoring and students focus on learning</div>
              <div className="py-12">✅ No upfront fees or hidden costs<br /><br />✅ Automatic invoices and payments<br /><br />✅ Lesson schedules and notes all in the same place<br /><br />✅ Free trial to ease any concerns<br /></div>
            </div>
            <div className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12`}>
              <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden"><img className="" src={noNonsense} alt="VCE Tutoring at Benchmark"></img></div>
            </div>
          </div>
        </div> 
      </div>
      <div className="">
        <div className="hidden sm:flex justify-center items-center">
          <div className="w-7/12 grid grid-cols-2 gap-12 py-24">
            <div className={`transition-opacity ease-in duration-1000 border-2 border-[#8FCB9B] rounded-xl p-3`}>
              <h2 className="text-3xl text-center  pb-3">Matched to the <span className='font-semibold'>perfect</span> tutor</h2>
              <div className="py-3 text-center">Whether its a VCE subject, a Pre-VCE subject or maybe you're just learning for fun. With our variety of tutors from a broad range of backgrounds and experiences, the team at Benchmark will match you to the perfect tutor for your needs and interests. Our tutors are all kind, easy-going and approachable but can also be strict and stern when it matters most.</div>
            </div>
            <div className={`transition-opacity ease-in duration-1000 border-2 border-[#8FCB9B] rounded-xl p-3`}>
              <h3 className="text-3xl text-center pb-3">Lessons with <span className='font-semibold'>you</span> at the centre</h3>
              <div className="py-3 text-center">At Benchmark Tutoring, we believe that tutoring should be targeted, specific and relevant to you. We believe that tutoring should not consist of a rigid schedule or teaching techniques. Our lessons are structured with the student's needs in mind. That means you get to spend as little or as much time as needed on the topics that are relevant to you.</div>
            </div>
          </div>
        </div>
        <div className="flex sm:hidden justify-center items-center">
          <div className="w-10/12 grid grid-cols-1 gap-12 py-24">
            <div className={`transition-opacity ease-in duration-1000 border-2 border-[#8FCB9B] rounded-xl p-3 mb-24`}>
              <h2 className="text-2xl text-center  pb-3">Matched to the <span className='font-semibold'>perfect</span> tutor</h2>
              <div className="py-3 text-center">Whether its a VCE subject, a Pre-VCE subject or maybe you're just learning for fun. With our variety of tutors from a broad range of backgrounds and experiences, the team at Benchmark will match you to the perfect tutor for your needs and interests. Our tutors are all kind, easy-going and approachable but can also be strict and stern when it matters most.</div>
            </div>
            <div className={`transition-opacity ease-in duration-1000 border-2 border-[#8FCB9B] rounded-xl p-3`}>
              <h3 className="text-2xl text-center pb-3">Lessons with <span className='font-semibold'>you</span> at the centre</h3>
              <div className="py-3 text-center">At Benchmark Tutoring, we believe that tutoring should be targeted, specific and relevant to you. We believe that tutoring should not consist of a rigid schedule or teaching techniques. Our lessons are structured with the student's needs in mind. That means you get to spend as little or as much time as needed on the topics that are relevant to you.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center bg-[#8fcb9b] px-5 py-32 pb-12">
        <h2 className="text-xl text-center sm:text-3xl">Get started with a free trial with one of the best VCE tutors in Melbourne.</h2>
      </div>
      <div className="text-center bg-[#8fcb9b] text-lg pb-32">Click <Link to="/contact" className="text-[#dceedc] underline" onClick={() => window.scrollTo(0, 0)}>here</Link> to get started!</div>
      <div className="flex items-center justify-center bg-white px-5 py-32">
        <h2 className="text-xl text-center sm:text-3xl">Here are just a few of the success stories at Benchmark...</h2>
      </div>
      <div className="flex items-center justify-center bg-white pt-0 p-32">
        <div className="w-[300px] sm:w-[800px]">
          <div ref={ref1} className={`transition-opacity ease-in duration-1000 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
            <div className='text-center p-2 sm:hidden'></div>
            <Carousel reviews={reviews} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center bg-white pt-0 pb-0 px-5">
        <h3 className="text-center text-2xl sm:text-3xl">How do I get started?</h3>
      </div>
      <div className="flex items-center justify-center p-12">
          <div className="text-md">Getting started with one of our top VCE tutors is a simple 4 step process.</div>
      </div>
      <div className="flex items-center justify-center pb-12 sm:pb-20">
        <div ref={ref2} className={`transition-opacity w-9/12 sm:w-5/12 ease-in duration-1000 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
          <Link to="/contact" onClick={() => window.scrollTo(0, 0)} className="w-1/2" href="https://www.facebook.com/benchmark.tutoring">
            <img className="hidden sm:block rounded-xl" src={flowchart} alt="How to get started"></img>
          </Link>
          <Link to="/contact" onClick={() => window.scrollTo(0, 0)} className="w-full" href="https://www.facebook.com/benchmark.tutoring">
            <img className="block sm:hidden" src={vertical1} alt="How to get started"></img>
          </Link>
          <Link to="/contact" onClick={() => window.scrollTo(0, 0)} className="w-full" href="https://www.facebook.com/benchmark.tutoring">
            <img className="block sm:hidden" src={vertical2} alt="How to get started"></img>
          </Link>
        </div>  
      </div>
      <div className="bg-[#8fcb9b]">
        <h2 className={`flex text-2xl sm:text-3xl transition-opacity items-center justify-center ease-in duration-1000 w-full pt-20 p-6`}>
          Why should you get a tutor?
        </h2>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Every year, Australia's education system only gets <span className="font-semibold">more and more competitive</span>. Studies show that at least <span className="font-semibold">one in every four</span> parents have gotten tutoring for their child. </div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Particularly in response to the effects of online learning due to COVID-19, additional coaching services outside of school hours is rapidly gaining popularity. This means that your child may be <span className="font-semibold">at risk of being disadvantaged </span> in the classroom.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6 pb-20 mb-4`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Of course, tutoring is  <span className="font-semibold">not essential</span> to score well in school, but it is true that every bit counts. In your child's case, having a  <span className="font-semibold">dedicated and committed</span> VCE tutor could be the make-or-break for them getting their dream VCE ATAR score.</div>
            </div>
        </div>
      </div>
      <div className="flex items-center justify-center bg-white py-16 px-5">
        <h3 className="text-center sm:text-lg">Read on to find out about the VCE...</h3>
      </div>
      <h2 className={`flex text-2xl sm:text-3xl transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full p-6 pb-12`}>
        What is the VCE?
      </h2>
      <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
          <div className="w-11/12 sm:w-1/2">
              <div className="text-md sm:text-lg p-3 py-2">VCE stands for the <span className="font-semibold">Victorian Certificate of Education</span> - it's the final high school certificate that the majority of Victorian high school students receive in their final year of school. The VCE is also important for pathways into further education such as TAFE and University.</div>
          </div>
      </div>
      <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
          <div className="w-11/12 sm:w-1/2">
              <div className="text-md sm:text-lg p-3 py-2">The VCE is made up of a minimum of 20 units and is typically studied over 2 years (5 units per semester). Subjects are normally studied accross 4 units - units 1, 2, 3 and 4, with units 1 and 2 being typically completed in year 11 and units 3 and 4 being typically completed in year 12. </div>
          </div>
      </div>
      <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
          <div className="w-11/12 sm:w-1/2">
              <div className="text-md sm:text-lg p-3 py-2">However the VCE also offers lots of freedom in student's learning including offering over 90 subjects. There are 4 different English subjects, 5 different mathematics and over 40 languages other than English! It is also not uncommon to see students complete unit 1/2 or 3/4 subjects a year (or more) early, or even complete their VCE across 3+ years.</div>
          </div>
      </div>
      <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 pb-24`}>
          <div className="w-11/12 sm:w-1/2">
              <div className="text-md sm:text-lg p-3 py-2">At the end of VCE, all students receive an ATAR. This is the "score" that universities primarily use to select the students for their courses. However, other factors are also taken into account depending on the course, and alternative pathways are always available.</div>
          </div>
      </div> 
      <h2 className={`flex text-2xl sm:text-3xl transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full p-6 pb-12`}>
        What is the ATAR?
      </h2>
      <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full p-6 pb-12`}>
            <div className="grid grid-cols-1">
                <div className="">
                    <div className="text-lg sm:text-xl p-3 pt-0 sm:pt-3"><span className="font-bold">ATAR</span> stands for:</div>
                    <div className="text-lg sm:text-xl p-3 py-1 sm:py-2"><span className="font-bold">A</span>ustralian</div>
                    <div className="text-lg sm:text-xl p-3 py-1 sm:py-2"><span className="font-bold">T</span>ertiary</div>
                    <div className="text-lg sm:text-xl p-3 py-1 sm:py-2"><span className="font-bold">A</span>dmission</div>
                    <div className="text-lg sm:text-xl p-3 py-1 sm:py-2"><span className="font-bold">R</span>ank</div>
                </div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white p-6 pb-0 sm:pb-8`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-center text-md sm:text-lg p-3 py-0 sm:py-2">As we can see from the name, the ATAR is a ranking that is used for university (and other tertiary institutions) admission.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full p-6 pb-0 sm:pb-8`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-center text-md sm:text-lg p-3 py-2">The key word that many people seem to overlook is <span className="font-semibold">"rank"</span>.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full p-6 pb-8`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-center text-md sm:text-lg p-3 py-2">That's right, the ATAR is a ranking. Your ATAR does not directly indicate the amount of questions you got right or the amount of marks you got in the exams. It is a direct indicator as to how many students (what percentage of students) <span className="font-semibold">you did better than</span> in the state.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 `}>
            <div className="w-11/12 sm:w-1/2">
                <div className="font-semibold text-md sm:text-lg p-3 py-2">Here's how it works.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 `}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Your <span className="font-semibold">highest VCE English study score</span> and your <span className="font-semibold">highest 3 non-English study scores</span> are added togther. Then 10% of your 5th and 6th highest study scores (if applicable) are also added to this. </div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 `}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">This total score is what we call your <span className="font-semibold">aggregate score</span>.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 `}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Your aggregate is what is used to determine your ranking for your ATAR.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Everyone has an aggregate score and now the top 0.05% of students get "assigned" a 99.95 ATAR, the next 0.05% of students get 99.90, the next 0.05% students get 99.85 and so on.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6 `}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Despite the ATAR being a ranking, we still want to channel our focus into <span className="font-semibold">our own studies and performance</span>. At the end of the day, that's the only thing we can control and that's how we're going to receive a quality education. If you need that <span className="font-semibold">little extra support</span> to get reach your full potential in year 12, the VCE tutors at Benchmark Tutoring can do just that.</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
            <div className="w-11/12 sm:w-1/2">
                <div className="text-md sm:text-lg p-3 py-2">Inquire today for a <span className="font-semibold">free</span> trial lesson!</div>
            </div>
        </div>
        <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 bg-white w-full px-6`}>
          <div className="w-11/12 sm:w-1/2">
            <div className="text-md sm:text-lg p-3 py-2 pb-32">Click <Link to="/contact" className="text-blue-300" onClick={() => window.scrollTo(0, 0)}>here </Link>to inquire.</div>         
          </div>
        </div>
    </> 
  )
}

export default Home