import React from 'react'
import { Link } from 'react-router-dom'

const MediaPost = ({ title, description, path }) => {
  return (
    <Link to={path} onClick={() => window.scrollTo(0, 0)}>
        <div className="bg-gray-100 mb-16 border rounded-lg border-black">
            <div className="text-lg font-semibold py-3 p-2">
                {title}
            </div>
            <div className="p-2">
                {description}
            </div>
        </div>
    </Link>
  )
}

export default MediaPost