export const questions = [
    {
      question: "Why Benchmark Tutoring?",
      answer:
        "Benchmark Tutoring places the focus on tutors first. We seek to match the industry standard in terms of pricing and excel on quality. Tutors are not just high achievers, but are experienced teachers and caring mentors. All tutors are trained individually and have undergone a thorough selection process. We can assure you that all tutors will uphold the highest standard.",
    },
    {
      question: "How do the free trial classes work?",
      answer:
        "As we are confident that our classes will provide unmatchable value to you, we’re willing to offer all prospective students a free one hour trial session. No commitment and no payment details required. The trial would likely involve: discussion of current weaknesses, a skills assessment component, and a future goals-setting segment. ",
    },
    {
      question: "Are there individual/group classes?",
      answer:
        "Individual and small group classes are both offered, so feel free to bring some friends! Students can choose the type of class that they think will be most beneficial to them, to ensure they get the most out of each class. Small group classes will consist of about 2-4 students.",
    },
    {
      question: "Where are classes held?",
      answer:
        "Almost all of our classes are held online. Where special arrangements are required, please contact us.",
    },
    {
      question: "How long is a class?",
      answer:
        "Classes are typically one hour long, once a week. However, alternative arrangements can be made with your tutor such as 90 minute classes, 2 classes a week, etc.",
    },
    {
      question: "How much do classes cost?",
      answer:
        "Classes are $60 an hour for VCE classes and $55 an hour for Pre-VCE classes.",
    },
    {
      question: "How do payments work?",
      answer:
        "We use a third-party software that can automatically generate and store invoices. Payments are made after lessons are completed, and you will never be required to make upfront payments. Payments have never been easier!",
    },
    
  ];