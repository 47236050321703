import React from 'react'
import { useRef } from 'react';
import { useIsVisible } from '../components/Visible';

import { Helmet } from 'react-helmet';

const TITLE = 'Pricing - Benchmark Tutoring';

const Pricing = () => {

    const ref0 = useRef();
    const isVisible0 = useIsVisible(ref0);
  
    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
          </script>
          <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
          </script>
        </Helmet>
        <div className="bg-white flex items-center justify-center">
          <div className="w-screen bg-[#8FCB9B] relative mb-24 overflow-hidden">
            <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
              <g clip-path="url(&quot;#SvgjsClipPath1177&quot;)" fill="none">
                  <rect width="2500" height="560" x="0" y="0" fill="rgba(143, 203, 155, 1)"></rect>
                  <circle r="93.335" cx="19.63" cy="42.57" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="93.335" cx="430.8" cy="464.87" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="93.335" cx="818.05" cy="695.79" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="93.335" cx="1139.21" cy="1195.25" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="93.335" cx="1435.44" cy="1523.97" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="93.335" cx="1924.12" cy="1809.28" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="183.71" cx="819.87" cy="52.65" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="98.635" cx="2088.66" cy="465.73" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="162.47" cx="1284.99" cy="126.14" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="51.115" cx="1160.16" cy="430.24" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="165.82" cx="2390.57" cy="398.4" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="71.815" cx="936.54" cy="549.13" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="48.805" cx="2206.24" cy="178.73" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="146.875" cx="411.2" cy="558.55" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="100.095" cx="762.25" cy="207.97" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="82.55" cx="136.37" cy="155.03" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="119.335" cx="1120.39" cy="205.93" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="118.665" cx="1689.56" cy="357.13" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="116.345" cx="717.81" cy="343.21" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="175.36" cx="1680.95" cy="298.54" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="146.935" cx="709.84" cy="153.11" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="112.72" cx="1574.6" cy="357.21" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
                  <circle r="156.295" cx="2055.41" cy="288.98" stroke="#5b9279" stroke-opacity="1" stroke-width="2"></circle>
              </g>
              <defs>
                  <clipPath id="SvgjsClipPath1177">
                      <rect width="2500" height="560" x="0" y="0"></rect>
                  </clipPath>
              </defs>
            </svg>
            <h1 className="hidden font-serif sm:block text-5xl text-black text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2">
              Pricing
            </h1>
            <div className="block font-serif sm:hidden w-9/12 text-4xl text-black text-center pb-12 absolute top-1/3 left-1/2 -translate-y-1/2 -translate-x-1/2">
              Pricing
            </div>
            <div className="hidden sm:block text-lg text-black text-center absolute top-1/2 left-1/2 -translate-x-1/2">
                Benchmark's one-to-one private tutoring classes are priced below. 
            </div>
            <div className="block sm:hidden w-10/12 text-md text-black text-center absolute top-1/2 left-1/2 -translate-y-1/4 -translate-x-1/2">
                Benchmark's one-to-one private tutoring classes are priced below.
            </div>
          </div>
        </div>
        <div ref={ref0} className={`flex justify-center pb-36 transition-opacity ease-in duration-1000 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
          <div className="w-10/12 sm:w-2/3 bg-white grid sm:flex justify-center align-top">
            <div className="w-full pb-24 sm:p-5">
              <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                </svg>
              </div>
              <div className="text-3xl text-center pt-12 p-5 pb-2">
                VCE Classes
              </div>
              <div className="text-[#8F8073] text-sm sm:text-md text-center pb-8">
                VCE subjects - Units 1/2 and Units 3/4
              </div>
              <div className="text-md sm:text-lg text-center">
                $60 per hour
              </div>
            </div>
            <div className="w-full p-5">
              <div className="flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                </svg>
              </div>
              <div className="text-3xl text-center pt-12 p-5 pb-2">
                Pre-VCE Classes
              </div>
              <div className="text-[#8F8073] text-sm sm:text-md text-center pb-8">
                Primary and High School subjects - Grade 1-6 and Year 7-10
              </div>
              <div className="text-md sm:text-lg text-center">
                $55 per hour
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white grid items-center justify-center p-6 pb-16">
            <div className="text-center text-lg pb-3">
                ✔️Free Trial Lesson with no commitment to ease any concerns.
            </div>
            <div className="text-center text-lg p-3">
                ✔️No upfront costs at all - only charged after each lesson! 
            </div>
            <div className="text-center text-lg p-3">
                ✔️Individually tailored lesson plans to your (your child's) needs. 
            </div>
            <div className="text-center text-lg p-3">
                ✔️Highly competent and compassionate tutors who are trained, experienced and accomplished.
            </div>
        </div>
        <div className="bg-[#ffffff]">
          <h2 className={`flex text-2xl sm:text-3xl transition-opacity items-center justify-center ease-in duration-1000 w-full pt-0 p-6`}>
            Cancellation Policy
          </h2>
          <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
              <div className="w-11/12 sm:w-1/2">
                  <div className="text-md sm:text-lg p-3 py-2">Flexibility for our students and tutors is something we value deeply at Benchmark Tutoring. <br/>
However, as our tutors are often putting in extra hours oustide of class to prepare for each lesson, we have established this cancellation policy to ensure our tutors are compensated fairly.<br/><br/></div>
              </div>
          </div>
          <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
              <div className="w-11/12 sm:w-1/2">
                  <div className="text-md sm:text-lg p-3 py-2"><span className="font-semibold">Cancellation Window:</span><br/>
                  To ensure smooth scheduling and avoid inconvenience, we kindly request that you provide at least 12 hours notice to your tutor in case of cancellation. This allows tutors ample time to adjust their schedules accordingly. Where no notice has been provided, you will be charged the equivalent of a one hour lesson. <br/><br/></div>
              </div>
          </div>
          <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
              <div className="w-11/12 sm:w-1/2">
                  <div className="text-md sm:text-lg p-3 py-2"><span className="font-semibold">Emergency Circumstances:</span><br/>
                  If you are in an emergency situation where you need to cancel on the day of your lesson, we recommend contacting your tutor as soon as possible. Keep in mind that your tutor will reserve the right to charge for the lesson, but in some cases they may be happy to reschedule!<br/><br/></div>
              </div>
          </div>
          <div className={`flex transition-opacity items-center justify-center ease-in duration-1000 w-full px-6`}>
              <div className="w-11/12 sm:w-1/2">
                  <div className="text-md sm:text-lg pb-16 p-3 py-2"><span className="font-semibold">No-Show Policy:</span><br/>
                  Late arrivals beyond 15 minutes will be considered a 'no-show,' resulting in the automatic cancellation of the class and the application of the full tutoring fee. We appreciate your understanding and cooperation in adhering to scheduled lesson times.<br/><br/></div>
              </div>
          </div>
        </div>
      </>
    )
  }

export default Pricing