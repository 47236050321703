import { useState } from "react";
export default function Carousel({ reviews }) {
  let [current, setCurrent] = useState(0);

  return (
    <div className="overflow-hidden sm:w-full relative z-10">
      <div className="sm:hidden text-center pb-2">Swipe for more</div>
      <div className="flex sm:hidden justify-center items-center pb-5 opacity-80">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
        </svg>
      </div>
      <div
        className={`flex snap-x snap-mandatory overflow-auto sm:overflow-visible sm:snap-none sm:touch-none transition ease-out duration-40`}
        style={{
          transform: `translateX(-${current * ((window.innerWidth < 640) ? 300 : 800)}px)`,
        }}
      >
        {reviews.map((s) => {
          return (
            <div className="snap-center sm:snap-none">
              <div className="w-[300px] h-[750px] sm:w-[800px] sm:h-[325px]">
                <div className="font-md leading-relaxed">{s[0]}</div>
                <div className="font-md font-bold leading-loose">{s[1]}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="hidden sm:flex absolute bottom-0 py-4 justify-center gap-3 w-full">
        {reviews.map((s, i) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`rounded-full w-5 h-5 cursor-pointer  ${
                i === current ? "bg-[#5B9279]" : "bg-gray-400"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}