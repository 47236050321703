import React from 'react'
import { useRef } from 'react';
import { useIsVisible } from '../components/Visible';
import maths1 from "../assets/maths1.png";
import maths2 from "../assets/maths2.png";

import { Helmet } from 'react-helmet';

const TITLE = 'VCE Maths Tutoring - Benchmark Tutoring';


const Maths = () => {

  const ref0 = useRef();
  const isVisible0 = useIsVisible(ref0);

  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);


  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-[#EAE6E5]">
        <div className="bg-[#5B9279] grid justify-center relative w-full overflow-hidden">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none" viewBox="0 0 2500 560">
            <g mask="url(&quot;#SvgjsMask1360&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(143, 203, 155, 1)"></rect>
                <path d="M-88.82 397.98L-88.82 397.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-88.82 397.98L-39.19 504.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-39.19 504.04L-39.19 504.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-39.19 504.04L41.06 490.07" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L-42.79 678.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L-39.19 504.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L41.06 490.07" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L-88.82 397.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L219.08 538.17" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M-42.79 678.44L106.02 403.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M106.02 403.86L106.02 403.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M106.02 403.86L41.06 490.07" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M106.02 403.86L228.32 350.56" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M106.02 403.86L219.08 538.17" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M41.06 490.07L41.06 490.07" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M41.06 490.07L-88.82 397.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M41.06 490.07L219.08 538.17" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M228.32 350.56L228.32 350.56" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M228.32 350.56L379.4 403.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M219.08 538.17L219.08 538.17" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M219.08 538.17L228.32 350.56" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M219.08 538.17L409.32 518.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M219.08 538.17L379.4 403.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M219.08 538.17L380.76 685.37" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M379.4 403.83L379.4 403.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M379.4 403.83L409.32 518.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M409.32 518.64L409.32 518.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M409.32 518.64L528.79 500.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M409.32 518.64L380.76 685.37" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M409.32 518.64L539.32 351.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M409.32 518.64L540.5 707.89" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M380.76 685.37L380.76 685.37" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M380.76 685.37L540.5 707.89" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L539.32 351.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L528.79 500.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L683.6 399.13" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L379.4 403.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L673.88 558.87" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M539.32 351.24L797.26 374.06" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M528.79 500.83L528.79 500.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M528.79 500.83L673.88 558.87" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M528.79 500.83L379.4 403.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M528.79 500.83L683.6 399.13" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M528.79 500.83L540.5 707.89" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M540.5 707.89L540.5 707.89" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M540.5 707.89L688.55 693.15" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M540.5 707.89L673.88 558.87" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M683.6 399.13L683.6 399.13" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M683.6 399.13L797.26 374.06" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M683.6 399.13L673.88 558.87" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M673.88 558.87L673.88 558.87" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M673.88 558.87L688.55 693.15" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M673.88 558.87L850.79 559.66" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M688.55 693.15L688.55 693.15" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M688.55 693.15L850.79 559.66" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M688.55 693.15L528.79 500.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M797.26 374.06L797.26 374.06" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M797.26 374.06L948.79 372.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M850.79 559.66L850.79 559.66" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M850.79 559.66L970.4 638.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M850.79 559.66L797.26 374.06" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L948.79 372.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L1095.86 349.09" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L1092.29 234.9" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L850.79 559.66" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L1112.62 534.63" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M948.79 372.98L683.6 399.13" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L970.4 638.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L1103.22 641.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L1112.62 534.63" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L948.79 372.98" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L688.55 693.15" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M970.4 638.88L1245.2 518.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1092.29 234.9L1092.29 234.9" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1092.29 234.9L1095.86 349.09" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1092.29 234.9L1294.13 200.19" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1092.29 234.9L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1095.86 349.09L1095.86 349.09" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1095.86 349.09L1112.62 534.63" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1095.86 349.09L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1095.86 349.09L1245.2 518.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1095.86 349.09L1294.13 200.19" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1112.62 534.63L1112.62 534.63" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1112.62 534.63L1103.22 641.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1112.62 534.63L1245.2 518.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1112.62 534.63L1303.52 669.84" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1103.22 641.38L1103.22 641.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1103.22 641.38L1245.2 518.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1103.22 641.38L1303.52 669.84" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1294.13 200.19L1294.13 200.19" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1294.13 200.19L1415.58 246.41" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1294.13 200.19L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1294.13 200.19L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1298.62 361.32L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1298.62 361.32L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1245.2 518.04L1245.2 518.04" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1245.2 518.04L1303.52 669.84" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1245.2 518.04L1409.87 525.55" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1245.2 518.04L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1303.52 669.84L1303.52 669.84" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1303.52 669.84L1417.72 687.49" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1415.58 246.41L1415.58 246.41" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1415.58 246.41L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1415.58 246.41L1298.62 361.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1406.37 385.64L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1409.87 525.55L1409.87 525.55" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1409.87 525.55L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1409.87 525.55L1417.72 687.49" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1417.72 687.49L1417.72 687.49" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1417.72 687.49L1545.34 639.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1595.02 -78.02L1595.02 -78.02" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1595.02 -78.02L1758.44 -80.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1595.02 -78.02L1561.39 88.33" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1595.02 -78.02L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1595.02 -78.02L1871.2 -83.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1561.39 88.33" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1604.55 237.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1415.58 246.41" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1757.39 248.58" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1561.39 88.33L1758.44 -80.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1604.55 237.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1557.01 365.74" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1757.39 248.58" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1415.58 246.41" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1604.55 237.83L1715.63 395.36" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1557.01 365.74L1557.01 365.74" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1557.01 365.74L1406.37 385.64" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1557.01 365.74L1715.63 395.36" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1557.01 365.74L1592.12 531.62" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1592.12 531.62L1592.12 531.62" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1592.12 531.62L1545.34 639.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1592.12 531.62L1730.88 514.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1592.12 531.62L1710.4 646.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1545.34 639.43L1545.34 639.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1545.34 639.43L1710.4 646.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1758.44 -80.12L1758.44 -80.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1758.44 -80.12L1871.2 -83.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1758.44 -80.12L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1758.44 -80.12L1911.18 51.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1696.86 88.4L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1696.86 88.4L1757.39 248.58" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1757.39 248.58L1757.39 248.58" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1757.39 248.58L1715.63 395.36" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1757.39 248.58L1842.7 391.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1715.63 395.36L1715.63 395.36" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1715.63 395.36L1730.88 514.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1715.63 395.36L1842.7 391.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1730.88 514.38L1730.88 514.38" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1730.88 514.38L1710.4 646.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1730.88 514.38L1879.55 559.3" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1730.88 514.38L1842.7 391.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1730.88 514.38L1897.43 643.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1710.4 646.44L1710.4 646.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1871.2 -83.28L1871.2 -83.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1871.2 -83.28L1911.18 51.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1871.2 -83.28L2059.78 -44.31" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1871.2 -83.28L1995.54 103.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1871.2 -83.28L1696.86 88.4" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1911.18 51.65L1911.18 51.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1911.18 51.65L1995.54 103.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1911.18 51.65L2059.78 -44.31" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1842.7 391.45L1842.7 391.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1842.7 391.45L1879.55 559.3" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1842.7 391.45L2057.99 379.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1879.55 559.3L1879.55 559.3" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1879.55 559.3L1897.43 643.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1879.55 559.3L2009.86 538.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1879.55 559.3L1710.4 646.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1879.55 559.3L2016.22 710.14" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1897.43 643.45L1897.43 643.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1897.43 643.45L2016.22 710.14" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2059.78 -44.31L2059.78 -44.31" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2059.78 -44.31L2181.77 -106.37" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2059.78 -44.31L2175.15 57.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2059.78 -44.31L1995.54 103.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1995.54 103.88L1995.54 103.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1995.54 103.88L2030.42 224.42" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M1995.54 103.88L2175.15 57.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2030.42 224.42L2030.42 224.42" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2030.42 224.42L2180.88 221.6" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2030.42 224.42L2057.99 379.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2030.42 224.42L2142.41 399.11" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2057.99 379.57L2057.99 379.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2057.99 379.57L2142.41 399.11" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2057.99 379.57L2009.86 538.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2057.99 379.57L2180.88 221.6" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2057.99 379.57L2196.7 557.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2009.86 538.24L2009.86 538.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2009.86 538.24L1897.43 643.45" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2009.86 538.24L2016.22 710.14" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2009.86 538.24L2152.62 657.7" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2016.22 710.14L2016.22 710.14" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L2181.77 -106.37" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L2317.74 -78.53" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L2175.15 57.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L1995.54 103.88" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2181.77 -106.37L1871.2 -83.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2175.15 57.28L2175.15 57.28" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2175.15 57.28L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2175.15 57.28L2180.88 221.6" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2175.15 57.28L2317.74 -78.53" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2180.88 221.6L2180.88 221.6" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2180.88 221.6L2337.42 193.8" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2180.88 221.6L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2142.41 399.11L2142.41 399.11" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2142.41 399.11L2306.88 399.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2142.41 399.11L2196.7 557.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2196.7 557.65L2196.7 557.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2196.7 557.65L2152.62 657.7" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2196.7 557.65L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2152.62 657.7L2152.62 657.7" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2152.62 657.7L2016.22 710.14" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2152.62 657.7L2345.35 701.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2317.74 -78.53L2317.74 -78.53" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2317.74 -78.53L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2317.74 -78.53L2505.46 -48.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2317.74 -78.53L2470.01 63.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2290.77 79.35L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2290.77 79.35L2337.42 193.8" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2290.77 79.35L2470.01 63.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2290.77 79.35L2472.36 188.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2337.42 193.8L2337.42 193.8" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2337.42 193.8L2472.36 188.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2337.42 193.8L2470.01 63.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2337.42 193.8L2306.88 399.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2306.88 399.12L2306.88 399.12" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2306.88 399.12L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2306.88 399.12L2472.36 383.48" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2332.83 552.86L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2332.83 552.86L2345.35 701.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2345.35 701.44L2345.35 701.44" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2345.35 701.44L2459.45 682.03" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2345.35 701.44L2196.7 557.65" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2345.35 701.44L2508.87 537.05" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2345.35 701.44L2644.39 709.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2505.46 -48.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2602.93 -81.52" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2470.01 63.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2645.32 52.29" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2472.36 188.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2505.46 -48.57L2290.77 79.35" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2470.01 63.83L2470.01 63.83" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2470.01 63.83L2472.36 188.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2470.01 63.83L2645.32 52.29" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2470.01 63.83L2602.93 -81.52" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 188.24L2472.36 188.24" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 188.24L2620.53 257.81" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 188.24L2472.36 383.48" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 383.48L2472.36 383.48" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 383.48L2508.87 537.05" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 383.48L2647.3 341.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 383.48L2620.53 257.81" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2472.36 383.48L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2508.87 537.05L2508.87 537.05" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2508.87 537.05L2631.36 547.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2508.87 537.05L2459.45 682.03" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2459.45 682.03L2459.45 682.03" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2459.45 682.03L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2459.45 682.03L2644.39 709.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2459.45 682.03L2631.36 547.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2602.93 -81.52L2602.93 -81.52" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2645.32 52.29L2645.32 52.29" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2645.32 52.29L2602.93 -81.52" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2620.53 257.81L2620.53 257.81" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2620.53 257.81L2647.3 341.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2647.3 341.43L2647.3 341.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2631.36 547.57L2631.36 547.57" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2631.36 547.57L2644.39 709.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2644.39 709.32L2644.39 709.32" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2644.39 709.32L2508.87 537.05" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2644.39 709.32L2332.83 552.86" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <path d="M2644.39 709.32L2647.3 341.43" stroke="rgba(91, 146, 121, 1)" stroke-width="1.5"></path>
                <circle r="5" cx="-88.82" cy="397.98" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="-39.19" cy="504.04" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="-42.79" cy="678.44" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="106.02" cy="403.86" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="41.06" cy="490.07" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="228.32" cy="350.56" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="219.08" cy="538.17" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="379.4" cy="403.83" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="409.32" cy="518.64" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="380.76" cy="685.37" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="539.32" cy="351.24" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="528.79" cy="500.83" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="540.5" cy="707.89" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="683.6" cy="399.13" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="673.88" cy="558.87" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="688.55" cy="693.15" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="797.26" cy="374.06" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="850.79" cy="559.66" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="948.79" cy="372.98" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="970.4" cy="638.88" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1092.29" cy="234.9" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1095.86" cy="349.09" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1112.62" cy="534.63" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1103.22" cy="641.38" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1294.13" cy="200.19" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1298.62" cy="361.32" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1245.2" cy="518.04" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1303.52" cy="669.84" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1415.58" cy="246.41" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1406.37" cy="385.64" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1409.87" cy="525.55" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1417.72" cy="687.49" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1595.02" cy="-78.02" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1561.39" cy="88.33" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1604.55" cy="237.83" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1557.01" cy="365.74" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1592.12" cy="531.62" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1545.34" cy="639.43" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1758.44" cy="-80.12" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1696.86" cy="88.4" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1757.39" cy="248.58" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1715.63" cy="395.36" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1730.88" cy="514.38" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1710.4" cy="646.44" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1871.2" cy="-83.28" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1911.18" cy="51.65" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1842.7" cy="391.45" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1879.55" cy="559.3" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1897.43" cy="643.45" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2059.78" cy="-44.31" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="1995.54" cy="103.88" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2030.42" cy="224.42" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2057.99" cy="379.57" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2009.86" cy="538.24" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2016.22" cy="710.14" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2181.77" cy="-106.37" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2175.15" cy="57.28" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2180.88" cy="221.6" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2142.41" cy="399.11" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2196.7" cy="557.65" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2152.62" cy="657.7" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2317.74" cy="-78.53" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2290.77" cy="79.35" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2337.42" cy="193.8" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2306.88" cy="399.12" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2332.83" cy="552.86" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2345.35" cy="701.44" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2505.46" cy="-48.57" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2470.01" cy="63.83" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2472.36" cy="188.24" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2472.36" cy="383.48" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2508.87" cy="537.05" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2459.45" cy="682.03" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2602.93" cy="-81.52" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2645.32" cy="52.29" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2620.53" cy="257.81" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2647.3" cy="341.43" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2631.36" cy="547.57" fill="rgba(91, 146, 121, 1)"></circle>
                <circle r="5" cx="2644.39" cy="709.32" fill="rgba(91, 146, 121, 1)"></circle>
                <path d="M-96.07 648.81L-96.07 648.81" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-96.07 648.81L-80.1 523.8" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-96.07 648.81L88.44 689.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-96.07 648.81L69.71 504.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-96.07 648.81L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M188.86 540.01L188.86 540.01" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M188.86 540.01L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M188.86 540.01L69.71 504.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M345.76 666.41L345.76 666.41" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M345.76 666.41L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L797.73 560" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L707.59 521.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L858.23 684.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L993.75 517.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M797.73 560L640.55 696.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M993.75 517.61L993.75 517.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M993.75 517.61L1088.72 544.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M993.75 517.61L1113.97 643.06" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M993.75 517.61L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M993.75 517.61L1137.97 410.59" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1113.97 643.06L1113.97 643.06" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1113.97 643.06L1088.72 544.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1113.97 643.06L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1113.97 643.06L1306.58 678.74" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1113.97 643.06L1284.68 533.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1446.94 407.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1559.88 407.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1559.75 498.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1409.25 560.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1284.68 533.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1691.14 410.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1446.94 407.19L1443.95 660.44" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2166.13 -91.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2294.2 -81.16" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2162.05 79.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2290.74 40.51" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2036.59 107.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2024.61 197.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2166.13 -91.15L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2294.2 -81.16L2294.2 -81.16" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2294.2 -81.16L2290.74 40.51" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.5 690.88L2498.5 690.88" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.5 690.88L2610.21 692.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.5 690.88L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.5 690.88L2598.7 530.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.5 690.88L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L-80.1 523.8" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L69.71 504.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L88.44 689.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L188.86 540.01" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-80.1 523.8L345.76 666.41" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M69.71 504.24L69.71 504.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M69.71 504.24L88.44 689.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M69.71 504.24L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M88.44 689.91L88.44 689.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M88.44 689.91L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M88.44 689.91L188.86 540.01" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M197.23 649.35L197.23 649.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M498.35 677.99L498.35 677.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M498.35 677.99L640.55 696.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M498.35 677.99L345.76 666.41" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M498.35 677.99L707.59 521.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M707.59 521.45L707.59 521.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M707.59 521.45L640.55 696.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M707.59 521.45L858.23 684.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M707.59 521.45L993.75 517.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M707.59 521.45L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M640.55 696.19L640.55 696.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M858.23 684.91L858.23 684.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M858.23 684.91L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M858.23 684.91L993.75 517.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M858.23 684.91L640.55 696.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M950.38 689.56L950.38 689.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1137.97 410.59L1137.97 410.59" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1137.97 410.59L1088.72 544.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1137.97 410.59L1284.68 533.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1137.97 410.59L1113.97 643.06" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1137.97 410.59L1446.94 407.19" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1088.72 544.65L1088.72 544.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1284.68 533.24L1284.68 533.24" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1284.68 533.24L1409.25 560.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1284.68 533.24L1306.58 678.74" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1284.68 533.24L1088.72 544.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1306.58 678.74L1306.58 678.74" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1306.58 678.74L1443.95 660.44" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1409.25 560.35L1409.25 560.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1409.25 560.35L1443.95 660.44" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1409.25 560.35L1306.58 678.74" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1443.95 660.44L1443.95 660.44" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1443.95 660.44L1594.18 677.77" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.88 407.43L1559.88 407.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.88 407.43L1559.75 498.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.88 407.43L1691.14 410.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.88 407.43L1409.25 560.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.88 407.43L1756.06 542.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1559.75 498.65L1559.75 498.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1594.18 677.77L1594.18 677.77" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1594.18 677.77L1695.48 710.49" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1691.14 410.23L1691.14 410.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1691.14 410.23L1756.06 542.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1691.14 410.23L1559.75 498.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1691.14 410.23L1881.59 397.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1691.14 410.23L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1756.06 542.15L1756.06 542.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1756.06 542.15L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1756.06 542.15L1852.47 651.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1695.48 710.49" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1852.47 651.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1756.06 542.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1559.75 498.65" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1443.95 660.44" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1695.48 710.49L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1910.91 225.85L1910.91 225.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1910.91 225.85L2024.61 197.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1910.91 225.85L2036.59 107.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1910.91 225.85L1881.59 397.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1910.91 225.85L1994.88 387.22" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1881.59 397.76L1881.59 397.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1881.59 397.76L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1881.59 397.76L1994.88 387.22" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1877.97 503.6L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1877.97 503.6L1852.47 651.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1877.97 503.6L1994.88 387.22" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1877.97 503.6L2047.47 501.49" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1852.47 651.15L1852.47 651.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2036.59 107.56L2036.59 107.56" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2036.59 107.56L2024.61 197.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2036.59 107.56L2162.05 79.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2024.61 197.28L2024.61 197.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1994.88 387.22L1994.88 387.22" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1994.88 387.22L2047.47 501.49" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2047.47 501.49L2047.47 501.49" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2047.47 501.49L2180.29 509.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2047.47 501.49L2023.01 683.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2047.47 501.49L1881.59 397.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2023.01 683.85L2023.01 683.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2023.01 683.85L2176.62 677.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2023.01 683.85L1852.47 651.15" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2023.01 683.85L1877.97 503.6" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2023.01 683.85L2180.29 509.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2162.05 79.91L2162.05 79.91" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2162.05 79.91L2290.74 40.51" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.44 257.76L2191.44 257.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.44 257.76L2211.5 350.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.44 257.76L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2211.5 350.23L2211.5 350.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2211.5 350.23L2362.37 389.72" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2211.5 350.23L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2180.29 509.85L2180.29 509.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2180.29 509.85L2308.52 537.34" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2180.29 509.85L2211.5 350.23" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2180.29 509.85L2176.62 677.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2180.29 509.85L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2176.62 677.67L2176.62 677.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2176.62 677.67L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2290.74 40.51L2290.74 40.51" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2290.74 40.51L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2290.74 40.51L2445.02 191.27" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2290.74 40.51L2508.25 101.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2295.44 211L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2295.44 211L2445.02 191.27" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2362.37 389.72L2362.37 389.72" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2362.37 389.72L2498.78 368.17" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2362.37 389.72L2308.52 537.34" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2362.37 389.72L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2362.37 389.72L2295.44 211" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2308.52 537.34L2308.52 537.34" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2308.52 537.34L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2308.52 537.34L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2308.52 537.34L2176.62 677.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2291.97 670.95L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.46 -69.5L2498.46 -69.5" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.46 -69.5L2636.88 -81.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.46 -69.5L2508.25 101.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.46 -69.5L2589.41 87.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2508.25 101.18L2508.25 101.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2508.25 101.18L2589.41 87.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2508.25 101.18L2445.02 191.27" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2445.02 191.27L2445.02 191.27" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2445.02 191.27L2589.41 87.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2445.02 191.27L2498.78 368.17" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2445.02 191.27L2658.45 192.11" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.78 368.17L2498.78 368.17" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.78 368.17L2614.69 350.08" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.78 368.17L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2498.78 368.17L2598.7 530.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2437.61 528.31L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2437.61 528.31L2598.7 530.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2636.88 -81.79L2636.88 -81.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2636.88 -81.79L2589.41 87.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2636.88 -81.79L2508.25 101.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2636.88 -81.79L2658.45 192.11" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2636.88 -81.79L2445.02 191.27" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2589.41 87.07L2589.41 87.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2589.41 87.07L2658.45 192.11" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2589.41 87.07L2614.69 350.08" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2658.45 192.11L2658.45 192.11" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2658.45 192.11L2614.69 350.08" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2658.45 192.11L2508.25 101.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2658.45 192.11L2498.78 368.17" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2614.69 350.08L2614.69 350.08" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2598.7 530.52L2598.7 530.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2598.7 530.52L2610.21 692.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2598.7 530.52L2614.69 350.08" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2598.7 530.52L2362.37 389.72" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2610.21 692.38L2610.21 692.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2610.21 692.38L2437.61 528.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2610.21 692.38L2291.97 670.95" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2610.21 692.38L2308.52 537.34" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <circle r="25" cx="-96.07" cy="648.81" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="188.86" cy="540.01" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="345.76" cy="666.41" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="797.73" cy="560" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="993.75" cy="517.61" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="1113.97" cy="643.06" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="1446.94" cy="407.19" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="2166.13" cy="-91.15" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="2294.2" cy="-81.16" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="25" cx="2498.5" cy="690.88" fill="url(&quot;#SvgjsRadialGradient1361&quot;)"></circle>
                <circle r="5" cx="-80.1" cy="523.8" fill="#f5f3f2"></circle>
                <circle r="5" cx="69.71" cy="504.24" fill="#f5f3f2"></circle>
                <circle r="5" cx="88.44" cy="689.91" fill="#f5f3f2"></circle>
                <circle r="5" cx="197.23" cy="649.35" fill="#f5f3f2"></circle>
                <circle r="5" cx="498.35" cy="677.99" fill="#f5f3f2"></circle>
                <circle r="5" cx="707.59" cy="521.45" fill="#f5f3f2"></circle>
                <circle r="5" cx="640.55" cy="696.19" fill="#f5f3f2"></circle>
                <circle r="5" cx="858.23" cy="684.91" fill="#f5f3f2"></circle>
                <circle r="5" cx="950.38" cy="689.56" fill="#f5f3f2"></circle>
                <circle r="5" cx="1137.97" cy="410.59" fill="#f5f3f2"></circle>
                <circle r="5" cx="1088.72" cy="544.65" fill="#f5f3f2"></circle>
                <circle r="5" cx="1284.68" cy="533.24" fill="#f5f3f2"></circle>
                <circle r="5" cx="1306.58" cy="678.74" fill="#f5f3f2"></circle>
                <circle r="5" cx="1409.25" cy="560.35" fill="#f5f3f2"></circle>
                <circle r="5" cx="1443.95" cy="660.44" fill="#f5f3f2"></circle>
                <circle r="5" cx="1559.88" cy="407.43" fill="#f5f3f2"></circle>
                <circle r="5" cx="1559.75" cy="498.65" fill="#f5f3f2"></circle>
                <circle r="5" cx="1594.18" cy="677.77" fill="#f5f3f2"></circle>
                <circle r="5" cx="1691.14" cy="410.23" fill="#f5f3f2"></circle>
                <circle r="5" cx="1756.06" cy="542.15" fill="#f5f3f2"></circle>
                <circle r="5" cx="1695.48" cy="710.49" fill="#f5f3f2"></circle>
                <circle r="5" cx="1910.91" cy="225.85" fill="#f5f3f2"></circle>
                <circle r="5" cx="1881.59" cy="397.76" fill="#f5f3f2"></circle>
                <circle r="5" cx="1877.97" cy="503.6" fill="#f5f3f2"></circle>
                <circle r="5" cx="1852.47" cy="651.15" fill="#f5f3f2"></circle>
                <circle r="5" cx="2036.59" cy="107.56" fill="#f5f3f2"></circle>
                <circle r="5" cx="2024.61" cy="197.28" fill="#f5f3f2"></circle>
                <circle r="5" cx="1994.88" cy="387.22" fill="#f5f3f2"></circle>
                <circle r="5" cx="2047.47" cy="501.49" fill="#f5f3f2"></circle>
                <circle r="5" cx="2023.01" cy="683.85" fill="#f5f3f2"></circle>
                <circle r="5" cx="2162.05" cy="79.91" fill="#f5f3f2"></circle>
                <circle r="5" cx="2191.44" cy="257.76" fill="#f5f3f2"></circle>
                <circle r="5" cx="2211.5" cy="350.23" fill="#f5f3f2"></circle>
                <circle r="5" cx="2180.29" cy="509.85" fill="#f5f3f2"></circle>
                <circle r="5" cx="2176.62" cy="677.67" fill="#f5f3f2"></circle>
                <circle r="5" cx="2290.74" cy="40.51" fill="#f5f3f2"></circle>
                <circle r="5" cx="2295.44" cy="211" fill="#f5f3f2"></circle>
                <circle r="5" cx="2362.37" cy="389.72" fill="#f5f3f2"></circle>
                <circle r="5" cx="2308.52" cy="537.34" fill="#f5f3f2"></circle>
                <circle r="5" cx="2291.97" cy="670.95" fill="#f5f3f2"></circle>
                <circle r="5" cx="2498.46" cy="-69.5" fill="#f5f3f2"></circle>
                <circle r="5" cx="2508.25" cy="101.18" fill="#f5f3f2"></circle>
                <circle r="5" cx="2445.02" cy="191.27" fill="#f5f3f2"></circle>
                <circle r="5" cx="2498.78" cy="368.17" fill="#f5f3f2"></circle>
                <circle r="5" cx="2437.61" cy="528.31" fill="#f5f3f2"></circle>
                <circle r="5" cx="2636.88" cy="-81.79" fill="#f5f3f2"></circle>
                <circle r="5" cx="2589.41" cy="87.07" fill="#f5f3f2"></circle>
                <circle r="5" cx="2658.45" cy="192.11" fill="#f5f3f2"></circle>
                <circle r="5" cx="2614.69" cy="350.08" fill="#f5f3f2"></circle>
                <circle r="5" cx="2598.7" cy="530.52" fill="#f5f3f2"></circle>
                <circle r="5" cx="2610.21" cy="692.38" fill="#f5f3f2"></circle>
            </g>
            <defs>
                <mask id="SvgjsMask1360">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
                <radialGradient id="SvgjsRadialGradient1361">
                    <stop stop-color="#ffffff" offset="0.1"></stop>
                    <stop stop-color="rgba(234, 230, 229, 1)" offset="0.2"></stop>
                    <stop stop-color="rgba(234, 230, 229, 0)" offset="1"></stop>
                </radialGradient>
            </defs>
          </svg>
          <h1 className="hidden font-serif sm:block text-dark absolute text-5xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            VCE Maths Tutoring
          </h1>
          <div className="visible font-serif sm:hidden text-dark absolute text-center leading-normal text-4xl top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
            VCE Maths Tutoring
          </div>
          <div className="hidden sm:block text-dark absolute text-center md:-translate-y-1/3 text-xl top-2/3 left-1/2 -translate-x-1/2 -translate-y-2/3">
            VCE Maths requires strong fundamentals and knowledge of the concepts. Here at Benchmark, we not only focus on developing strong skills and understanding for maths, but also teach tips and tricks to tackle VCAA questions that are likely to come up on the exam and in SACs.
          </div>
          <div className="visible sm:hidden w-5/6 text-dark absolute text-center text-md top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/6">
            VCE Maths requires strong fundamentals and knowledge of the concepts. Here at Benchmark, we not only focus on developing strong skills and understanding for maths, but also teach tips and tricks to tackle VCAA questions that are likely to come up on the exam and in SACs.
          </div>
        </div>
        <div ref={ref0} className={`pb-24 transition-opacity ease-in duration-1000 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
          <div className="flex items-center justify-center p-5">
            <div className="grid sm:hidden grid-cols-6 w-full gap-4">
              <div className="text-center col-span-2">Pre-VCE Maths (Years 1-10)</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                </svg>
              </div>
              <div className="text-center col-span-2">VCE General Maths</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                </svg>
              </div>
              <div className="text-center col-span-2">VCE Maths Methods</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.745 3A23.933 23.933 0 0 0 3 12c0 3.183.62 6.22 1.745 9M19.5 3c.967 2.78 1.5 5.817 1.5 9s-.533 6.22-1.5 9M8.25 8.885l1.444-.89a.75.75 0 0 1 1.105.402l2.402 7.206a.75.75 0 0 0 1.104.401l1.445-.889m-8.25.75.213.09a1.687 1.687 0 0 0 2.062-.617l4.45-6.676a1.688 1.688 0 0 1 2.062-.618l.213.09" />
                </svg>
              </div>
              <div className="text-center col-span-2">VCE Specialist Maths</div>
              <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                </svg>
              </div>
              </div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="text-xl p-3 w-1/12 text-center">Pre-VCE Maths (Years 1-10)</div>
            <div className="text-xl p-3 w-1/12 text-center">VCE General Maths</div>
            <div className="text-xl p-3 w-1/12 text-center">VCE Maths Methods</div>
            <div className="text-xl p-3 w-1/12 text-center">VCE Specialist Maths</div>
          </div>
          <div className="hidden sm:flex items-center justify-center">
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.745 3A23.933 23.933 0 0 0 3 12c0 3.183.62 6.22 1.745 9M19.5 3c.967 2.78 1.5 5.817 1.5 9s-.533 6.22-1.5 9M8.25 8.885l1.444-.89a.75.75 0 0 1 1.105.402l2.402 7.206a.75.75 0 0 0 1.104.401l1.445-.889m-8.25.75.213.09a1.687 1.687 0 0 0 2.062-.617l4.45-6.676a1.688 1.688 0 0 1 2.062-.618l.213.09" />
              </svg>
            </div>
            <div className="flex w-1/12 items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
              </svg>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="py-14 text-md sm:text-xl w-10/12 sm:w-1/2 text-center">
            In our Mathematics courses, our main focus is on tailoring our classes to each student’s needs. This is to ensure that we cover all the necessary fundamental concepts including any areas of weakness from previous years of study. The student’s understanding is our number one priority, so we can pace explanations of various topics and questions accordingly.
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex justify-center items-center">
        <div className="w-1/2 grid grid-cols-2 gap-16 py-24">
          <div ref={ref1} className={`transition-opacity ease-in duration-1000 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Class Structure</div>
            <div className="py-3 leading-loose">We create classes that best suit your unique needs as a student to make sure you can get the most out of each and every class. We are experienced not only in teaching the concepts that are part of the Maths courses, but also walking through practice/challenging questions, exam techniques, tips and tricks (that really go a long way in VCE Maths). Practice questions can also be provided to aid your study and revision!</div>
          </div>
          <div ref={ref1} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden "><img className="" src={maths1} alt="Maths example 1"></img></div>
          </div>
          <div ref={ref2} className={`transition-opacity ease-in duration-1000 grid justify-start items-center py-4 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[250px] sm:w-[300px] md:w-[330px] 2xl:w-[400px] border-2 border-[#8FCB9B] rounded-2xl overflow-hidden"><img className="" src={maths2} alt="Maths example 2"></img></div>
          </div>
          <div ref={ref2} className={`transition-opacity ease-in duration-1000 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Online whiteboard</div>
            <div className="py-3 leading-loose">Our tutors use a tablet and stylus for online maths classes, so explanations using diagrams, graphs and working out can be easily displayed as if it were an in person class. The whiteboard allows for a step-by-step interactive class where further explanation can be easily added when necessary. The notes from the class can also be saved and sent to the student as reference in the future!</div>
          </div>
        </div>
      </div>
      <div className="flex sm:hidden justify-center items-center">
        <div className="w-9/12 grid-cols-2 gap-16 py-24">
          <div ref={ref5} className={`transition-opacity ease-in duration-1000 ${isVisible5 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Class Structure</div>
            <div className="py-12">We create classes that best suit your unique needs as a student to make sure you can get the most out of each and every class. We are experienced not only in teaching the concepts that are part of the Maths courses, but also walking through practice/challenging questions, exam techniques, tips and tricks (that really go a long way in VCE Maths). Practice questions can also be provided to aid your study and revision!</div>
          </div>
          <div ref={ref5} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible5 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden "><img className="" src={maths1} alt="Maths example 1"></img></div>
          </div>
            <div ref={ref6} className={`transition-opacity ease-in duration-1000 ${isVisible6 ? "opacity-100" : "opacity-0"}`}>
            <div className="text-3xl">Online whiteboard</div>
            <div className="py-12">Our tutors use a tablet and stylus for online maths classes, so explanations using diagrams, graphs and working out can be easily displayed as if it were an in person class. The whiteboard allows for a step-by-step interactive class where further explanation can be easily added when necessary. The notes from the class can also be saved and sent to the student as reference in the future!</div>
          </div>
          <div ref={ref6} className={`transition-opacity ease-in duration-1000 grid justify-center items-center pb-12 ${isVisible6 ? "opacity-100" : "opacity-0"}`}>
            <div className="w-[280px] border-2 border-[#8FCB9B] rounded-xl overflow-hidden"><img className="" src={maths2} alt="Maths example 2"></img></div>
          </div>
        </div>
      </div>           
    </> 
  )
}

export default Maths