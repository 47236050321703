import React from 'react'
import MediaPost from '../components/MediaPost'
import { useState } from 'react';
import { posts } from '../assets/posts';

import { Helmet } from 'react-helmet';

const TITLE = 'Media - Benchmark Tutoring';

const Media = () => {

  const [cards] = useState(posts);

  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
      </Helmet>
      <div className="bg-white flex items-center justify-center">
        <div className="w-full bg-[#8FCB9B] relative mb-24 overflow-hidden">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g mask="url(&quot;#SvgjsMask1390&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(91, 146, 121, 1)"></rect>
                <path d="M-61.58 404.04L-61.58 404.04" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-61.58 404.04L-42.96 545.33" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-61.58 404.04L79.84 500.68" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-61.58 404.04L103.19 345.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-61.58 404.04L-43.17 660.53" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-61.58 404.04L200.11 411.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L-42.96 545.33" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L-43.17 660.53" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L79.84 500.68" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L105.85 641.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-42.96 545.33L103.19 345.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-43.17 660.53L-43.17 660.53" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-43.17 660.53L105.85 641.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M-43.17 660.53L79.84 500.68" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M103.19 345.65L103.19 345.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M103.19 345.65L200.11 411.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M103.19 345.65L79.84 500.68" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M103.19 345.65L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M103.19 345.65L345.34 378.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M79.84 500.68L79.84 500.68" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M79.84 500.68L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M79.84 500.68L105.85 641.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M79.84 500.68L200.11 411.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M105.85 641.25L105.85 641.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M105.85 641.25L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M105.85 641.25L249.38 683.76" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M200.11 411.57L200.11 411.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M195.68 550.78L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M249.38 683.76L249.38 683.76" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M249.38 683.76L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M249.38 683.76L392.74 643.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M345.34 378.87L345.34 378.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M345.34 378.87L353.54 523.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M345.34 378.87L200.11 411.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M353.54 523.98L353.54 523.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M353.54 523.98L392.74 643.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M353.54 523.98L195.68 550.78" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M392.74 643.87L392.74 643.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M392.74 643.87L515.79 692.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L556.08 389.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L645.81 385.67" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L653.15 542.92" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L345.34 378.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L353.54 523.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M556.08 389.26L823.28 389.4" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M515.79 692.2L515.79 692.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M515.79 692.2L665.35 642.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M645.81 385.67L645.81 385.67" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M645.81 385.67L653.15 542.92" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M645.81 385.67L823.28 389.4" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M645.81 385.67L665.35 642.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M645.81 385.67L345.34 378.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M653.15 542.92L653.15 542.92" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M653.15 542.92L665.35 642.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M653.15 542.92L515.79 692.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M653.15 542.92L823.28 389.4" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M665.35 642.61L665.35 642.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M665.35 642.61L851.68 699.03" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M665.35 642.61L392.74 643.87" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M665.35 642.61L556.08 389.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M823.28 389.4L823.28 389.4" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M823.28 389.4L967.62 359.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M823.28 389.4L986.74 562.11" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L851.68 699.03" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L971.17 706.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L986.74 562.11" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L653.15 542.92" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L1113.96 710.5" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M851.68 699.03L823.28 389.4" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L967.62 359.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L1089.56 359.9" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L1091.94 257.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L986.74 562.11" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M967.62 359.49L1266.74 404.18" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M986.74 562.11L986.74 562.11" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M986.74 562.11L971.17 706.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M986.74 562.11L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M986.74 562.11L1113.96 710.5" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M971.17 706.26L971.17 706.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M971.17 706.26L1113.96 710.5" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1091.94 257.98L1091.94 257.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1091.94 257.98L1089.56 359.9" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1091.94 257.98L1293.18 208.93" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1091.94 257.98L1266.74 404.18" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1091.94 257.98L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1089.56 359.9L1089.56 359.9" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1089.56 359.9L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1089.56 359.9L1266.74 404.18" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1089.56 359.9L986.74 562.11" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1119.51 497.86L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1113.96 710.5L1113.96 710.5" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1113.96 710.5L1261.69 648.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1113.96 710.5L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1293.18 208.93L1293.18 208.93" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1293.18 208.93L1403.48 208.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1293.18 208.93L1416.01 345.05" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1293.18 208.93L1266.74 404.18" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1293.18 208.93L1089.56 359.9" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1266.74 404.18L1266.74 404.18" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1266.74 404.18L1290.68 530.29" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1266.74 404.18L1416.01 345.05" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1266.74 404.18L1119.51 497.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1266.74 404.18L1403.16 530.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1290.68 530.29L1290.68 530.29" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1290.68 530.29L1403.16 530.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1290.68 530.29L1261.69 648.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1261.69 648.49L1261.69 648.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.48 208.22L1403.48 208.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.48 208.22L1416.01 345.05" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.48 208.22L1569.6 242.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.48 208.22L1587.46 75.62" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1416.01 345.05L1416.01 345.05" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1416.01 345.05L1585.74 362.17" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1416.01 345.05L1569.6 242.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.16 530.98L1403.16 530.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.16 530.98L1460.09 683.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1403.16 530.98L1261.69 648.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1460.09 683.61" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1604.45 653.21" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1261.69 648.49" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1603.03 519.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1290.68 530.29" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1460.09 683.61L1702.84 647.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1604.52 -107.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1690.83 -86.99" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1587.46 75.62" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1713.02 61.44" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1869.04 -80.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.52 -107.22L1838.25 47.73" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1587.46 75.62L1587.46 75.62" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1587.46 75.62L1713.02 61.44" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1587.46 75.62L1569.6 242.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1587.46 75.62L1698.17 216.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1587.46 75.62L1690.83 -86.99" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1569.6 242.86L1569.6 242.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1569.6 242.86L1585.74 362.17" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1569.6 242.86L1698.17 216.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1585.74 362.17L1585.74 362.17" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1585.74 362.17L1730.84 393.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1585.74 362.17L1603.03 519.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1603.03 519.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1604.45 653.21" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1750.42 499.63" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1702.84 647.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1730.84 393.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1603.03 519.47L1403.16 530.98" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.45 653.21L1604.45 653.21" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1604.45 653.21L1702.84 647.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1690.83 -86.99L1690.83 -86.99" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1690.83 -86.99L1713.02 61.44" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1713.02 61.44L1713.02 61.44" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1713.02 61.44L1838.25 47.73" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1713.02 61.44L1698.17 216.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1713.02 61.44L1869.04 -80.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1698.17 216.43L1698.17 216.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1698.17 216.43L1874.22 201.46" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1698.17 216.43L1730.84 393.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1698.17 216.43L1585.74 362.17" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1730.84 393.42L1730.84 393.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1730.84 393.42L1750.42 499.63" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1730.84 393.42L1843.94 396.1" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1730.84 393.42L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1750.42 499.63L1750.42 499.63" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1750.42 499.63L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1702.84 647.22L1702.84 647.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1702.84 647.22L1852.9 644.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1702.84 647.22L1750.42 499.63" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1702.84 647.22L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1869.04 -80.71L1869.04 -80.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1869.04 -80.71L1838.25 47.73" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1869.04 -80.71L2018.13 -46.13" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1869.04 -80.71L1690.83 -86.99" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1838.25 47.73L1838.25 47.73" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1838.25 47.73L1874.22 201.46" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1838.25 47.73L1989.88 93.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1838.25 47.73L1690.83 -86.99" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1838.25 47.73L2018.13 -46.13" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1874.22 201.46L1874.22 201.46" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1874.22 201.46L2009.9 238.76" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1874.22 201.46L1989.88 93.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1843.94 396.1L1843.94 396.1" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1843.94 396.1L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1843.94 396.1L1750.42 499.63" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1858.08 503.43L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1858.08 503.43L1852.9 644.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1852.9 644.71L1852.9 644.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1852.9 644.71L1995.02 545.7" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1852.9 644.71L2029.15 647.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2018.13 -46.13L2018.13 -46.13" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2018.13 -46.13L1989.88 93.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2018.13 -46.13L2162.17 -81.14" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1989.88 93.65L1989.88 93.65" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1989.88 93.65L2009.9 238.76" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1989.88 93.65L2189.51 44.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1989.88 93.65L1869.04 -80.71" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2009.9 238.76L2009.9 238.76" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2009.9 238.76L2056.37 392.14" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2009.9 238.76L2207.29 228.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2009.9 238.76L1843.94 396.1" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2009.9 238.76L1838.25 47.73" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L2056.37 392.14" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L1995.02 545.7" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L2157.78 538.94" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L1843.94 396.1" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L2207.29 228.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2056.37 392.14L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1995.02 545.7L1995.02 545.7" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1995.02 545.7L2029.15 647.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1995.02 545.7L1858.08 503.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1995.02 545.7L2157.78 538.94" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M1995.02 545.7L2159.98 651.85" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2029.15 647.43L2029.15 647.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2029.15 647.43L2159.98 651.85" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2162.17 -81.14L2162.17 -81.14" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2162.17 -81.14L2189.51 44.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2189.51 44.88L2189.51 44.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2189.51 44.88L2301.4 69.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2189.51 44.88L2207.29 228.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2189.51 44.88L2018.13 -46.13" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2207.29 228.47L2207.29 228.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2207.29 228.47L2314.15 254.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2207.29 228.47L2301.4 69.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2157.78 538.94L2157.78 538.94" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2157.78 538.94L2159.98 651.85" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2157.78 538.94L2029.15 647.43" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2157.78 538.94L2349.15 545.51" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2159.98 651.85L2159.98 651.85" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2357.74 -99.27L2357.74 -99.27" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2357.74 -99.27L2301.4 69.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2357.74 -99.27L2162.17 -81.14" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2357.74 -99.27L2189.51 44.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2357.74 -99.27L2486.56 81.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2301.4 69.2L2301.4 69.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2301.4 69.2L2486.56 81.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2314.15 254.88L2314.15 254.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2314.15 254.88L2331.96 387.67" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2331.96 387.67L2331.96 387.67" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2331.96 387.67L2468.27 412.24" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2331.96 387.67L2349.15 545.51" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2331.96 387.67L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2331.96 387.67L2207.29 228.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2349.15 545.51L2349.15 545.51" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2349.15 545.51L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2349.15 545.51L2289.46 691.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2289.46 691.47L2289.46 691.47" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2289.46 691.47L2159.98 651.85" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2289.46 691.47L2475.57 656.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2486.56 81.25L2486.56 81.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2486.56 81.25L2627.03 91.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2486.56 81.25L2474.33 243.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2474.33 243.86L2474.33 243.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2474.33 243.86L2606.68 235.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2474.33 243.86L2599.39 338.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2474.33 243.86L2314.15 254.88" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2468.27 412.24L2468.27 412.24" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2468.27 412.24L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2468.27 412.24L2599.39 338.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2468.27 412.24L2474.33 243.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2468.57 518.72L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2475.57 656.57" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2595.72 553.12" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2349.15 545.51" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2651.37 706.64" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2475.57 656.57L2468.27 412.24" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2596.24 -53.74" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2627.03 91.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2486.56 81.25" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2357.74 -99.27" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2606.68 235.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2301.4 69.2" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2596.24 -53.74L2474.33 243.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2627.03 91.26L2627.03 91.26" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2627.03 91.26L2606.68 235.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2627.03 91.26L2474.33 243.86" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2606.68 235.42L2606.68 235.42" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2606.68 235.42L2599.39 338.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2599.39 338.22L2599.39 338.22" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2595.72 553.12L2595.72 553.12" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2595.72 553.12L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2651.37 706.64L2651.37 706.64" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2651.37 706.64L2595.72 553.12" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2651.37 706.64L2468.57 518.72" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <path d="M2651.37 706.64L2349.15 545.51" stroke="rgba(143, 203, 155, 1)" stroke-width="1.5"></path>
                <circle r="5" cx="-61.58" cy="404.04" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="-42.96" cy="545.33" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="-43.17" cy="660.53" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="103.19" cy="345.65" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="79.84" cy="500.68" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="105.85" cy="641.25" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="200.11" cy="411.57" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="195.68" cy="550.78" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="249.38" cy="683.76" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="345.34" cy="378.87" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="353.54" cy="523.98" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="392.74" cy="643.87" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="556.08" cy="389.26" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="515.79" cy="692.2" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="645.81" cy="385.67" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="653.15" cy="542.92" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="665.35" cy="642.61" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="823.28" cy="389.4" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="851.68" cy="699.03" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="967.62" cy="359.49" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="986.74" cy="562.11" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="971.17" cy="706.26" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1091.94" cy="257.98" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1089.56" cy="359.9" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1119.51" cy="497.86" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1113.96" cy="710.5" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1293.18" cy="208.93" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1266.74" cy="404.18" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1290.68" cy="530.29" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1261.69" cy="648.49" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1403.48" cy="208.22" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1416.01" cy="345.05" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1403.16" cy="530.98" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1460.09" cy="683.61" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1604.52" cy="-107.22" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1587.46" cy="75.62" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1569.6" cy="242.86" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1585.74" cy="362.17" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1603.03" cy="519.47" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1604.45" cy="653.21" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1690.83" cy="-86.99" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1713.02" cy="61.44" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1698.17" cy="216.43" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1730.84" cy="393.42" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1750.42" cy="499.63" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1702.84" cy="647.22" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1869.04" cy="-80.71" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1838.25" cy="47.73" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1874.22" cy="201.46" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1843.94" cy="396.1" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1858.08" cy="503.43" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1852.9" cy="644.71" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2018.13" cy="-46.13" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1989.88" cy="93.65" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2009.9" cy="238.76" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2056.37" cy="392.14" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="1995.02" cy="545.7" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2029.15" cy="647.43" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2162.17" cy="-81.14" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2189.51" cy="44.88" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2207.29" cy="228.47" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2157.78" cy="538.94" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2159.98" cy="651.85" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2357.74" cy="-99.27" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2301.4" cy="69.2" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2314.15" cy="254.88" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2331.96" cy="387.67" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2349.15" cy="545.51" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2289.46" cy="691.47" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2486.56" cy="81.25" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2474.33" cy="243.86" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2468.27" cy="412.24" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2468.57" cy="518.72" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2475.57" cy="656.57" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2596.24" cy="-53.74" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2627.03" cy="91.26" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2606.68" cy="235.42" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2599.39" cy="338.22" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2595.72" cy="553.12" fill="rgba(143, 203, 155, 1)"></circle>
                <circle r="5" cx="2651.37" cy="706.64" fill="rgba(143, 203, 155, 1)"></circle>
                <path d="M494.81 709.36L494.81 709.36" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M494.81 709.36L535.97 556.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M494.81 709.36L660 701.04" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M494.81 709.36L410.89 548.78" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M963.58 499.06L963.58 499.06" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M963.58 499.06L821.7 550.75" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M963.58 499.06L960.46 673.57" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M963.58 499.06L1159.48 518.94" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M960.46 673.57L960.46 673.57" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M960.46 673.57L816.54 684.64" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M960.46 673.57L821.7 550.75" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1448.28 385.76" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1430.71 516.25" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1312.26 356.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1590.85 537.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1448.28 385.76L1708.7 363.69" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1430.71 516.25" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1590.85 537.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1391.95 679.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1312.26 356.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1430.71 516.25L1577.51 675.97" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1708.7 363.69" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1844.94 408.89" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1745.9 525.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1590.85 537.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1860.87 218.02" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1708.7 363.69L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2191.55 669.88" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2199 516.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2356.96 650.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2009.24 659.39" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2044.82 537.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2191.55 669.88L2348.35 537.86" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2348.35 537.86L2348.35 537.86" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2348.35 537.86L2356.96 650.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2348.35 537.86L2471.09 515.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2356.96 650.18L2356.96 650.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2356.96 650.18L2479.18 711.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2356.96 650.18L2471.09 515.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2356.96 650.18L2199 516.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2356.96 650.18L2591.92 680.62" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2612.12 -51.52L2612.12 -51.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2612.12 -51.52L2660.11 61.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2612.12 -51.52L2487.88 -55.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2641.59 378.66L2641.59 378.66" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2641.59 378.66L2511.83 359" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2641.59 378.66L2643.08 202.83" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-86.98 537.84L-86.98 537.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-86.98 537.84L-106.94 650.73" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-86.98 537.84L90.81 500.1" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-86.98 537.84L68.12 667.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-86.98 537.84L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-106.94 650.73L-106.94 650.73" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-106.94 650.73L68.12 667.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-106.94 650.73L90.81 500.1" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M-106.94 650.73L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M90.81 500.1L90.81 500.1" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M90.81 500.1L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M90.81 500.1L68.12 667.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M90.81 500.1L215.19 702.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M68.12 667.28L68.12 667.28" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M68.12 667.28L215.19 702.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M68.12 667.28L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M196.97 561.61L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M215.19 702.38L215.19 702.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M215.19 702.38L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M215.19 702.38L410.89 548.78" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M215.19 702.38L494.81 709.36" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M410.89 548.78L410.89 548.78" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M410.89 548.78L535.97 556.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M410.89 548.78L196.97 561.61" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M410.89 548.78L660 701.04" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M535.97 556.96L535.97 556.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M535.97 556.96L660 701.04" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M660 701.04L660 701.04" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M660 701.04L816.54 684.64" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M660 701.04L821.7 550.75" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M821.7 550.75L821.7 550.75" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M821.7 550.75L816.54 684.64" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M816.54 684.64L816.54 684.64" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M816.54 684.64L963.58 499.06" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M816.54 684.64L535.97 556.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1159.48 518.94L1159.48 518.94" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1159.48 518.94L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1159.48 518.94L1161.33 680.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1159.48 518.94L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1159.48 518.94L1312.26 356.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1161.33 680.43L1161.33 680.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1161.33 680.43L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1161.33 680.43L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1312.26 356.07L1312.26 356.07" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1312.26 356.07L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1312.26 356.07L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1312.26 356.07L1590.85 537.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1249.45 546.31L1249.45 546.31" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1249.45 546.31L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1249.45 546.31L1391.95 679.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1258.9 660.99L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1391.95 679.67L1391.95 679.67" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1391.95 679.67L1258.9 660.99" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1590.85 537.33L1590.85 537.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1590.85 537.33L1577.51 675.97" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1590.85 537.33L1745.9 525.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1590.85 537.33L1752.19 653.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1577.51 675.97L1577.51 675.97" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1577.51 675.97L1752.19 653.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1745.9 525.79L1745.9 525.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1745.9 525.79L1752.19 653.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1745.9 525.79L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1745.9 525.79L1844.94 408.89" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1745.9 525.79L1577.51 675.97" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1752.19 653.43L1752.19 653.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1860.87 218.02L1860.87 218.02" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1860.87 218.02L2025.91 223.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1860.87 218.02L1844.94 408.89" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1860.87 218.02L2007.94 58.05" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1844.94 408.89L1844.94 408.89" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1844.94 408.89L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1844.94 408.89L1990.17 403.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1878.88 538.84L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L1896.09 711.35" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L2009.24 659.39" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L1752.19 653.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L2044.82 537.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L1745.9 525.79" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1896.09 711.35L2191.55 669.88" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2009.23 -41.73L2009.23 -41.73" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2009.23 -41.73L2007.94 58.05" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2009.23 -41.73L2168.31 -74.16" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2009.23 -41.73L2152.48 80.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2007.94 58.05L2007.94 58.05" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2007.94 58.05L2152.48 80.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2007.94 58.05L2025.91 223.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2007.94 58.05L2168.31 -74.16" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2025.91 223.92L2025.91 223.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2025.91 223.92L2161.55 250.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L1990.17 403.33" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L2044.82 537.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L1878.88 538.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L2025.91 223.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L2163.79 343.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M1990.17 403.33L1860.87 218.02" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2044.82 537.85L2044.82 537.85" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2044.82 537.85L2009.24 659.39" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2044.82 537.85L2199 516.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2009.24 659.39L2009.24 659.39" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2168.31 -74.16L2168.31 -74.16" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2168.31 -74.16L2314.34 -93.94" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2168.31 -74.16L2152.48 80.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2152.48 80.43L2152.48 80.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2152.48 80.43L2161.55 250.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2161.55 250.84L2161.55 250.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2161.55 250.84L2163.79 343.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2163.79 343.18L2163.79 343.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2163.79 343.18L2199 516.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2163.79 343.18L2025.91 223.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2199 516.47L2199 516.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2199 516.47L2348.35 537.86" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2314.34 -93.94L2314.34 -93.94" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2314.34 -93.94L2352.76 53.72" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2314.34 -93.94L2487.88 -55.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2352.76 53.72L2352.76 53.72" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2352.76 53.72L2340.5 194.57" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2340.5 194.57" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2457.28 219.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2352.37 357.01" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2161.55 250.84" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2511.98 71.9" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2340.5 194.57L2152.48 80.43" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2352.37 357.01L2352.37 357.01" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2352.37 357.01L2511.83 359" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2352.37 357.01L2457.28 219.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2487.88 -55.18L2487.88 -55.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2487.88 -55.18L2511.98 71.9" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.98 71.9L2511.98 71.9" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.98 71.9L2660.11 61.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2457.28 219.47L2457.28 219.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2457.28 219.47L2511.83 359" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2457.28 219.47L2511.98 71.9" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.83 359L2511.83 359" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.83 359L2471.09 515.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.83 359L2643.08 202.83" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2511.83 359L2603.19 553.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2471.09 515.96L2471.09 515.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2471.09 515.96L2603.19 553.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2471.09 515.96L2479.18 711.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2479.18 711.45L2479.18 711.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2479.18 711.45L2591.92 680.62" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2660.11 61.92L2660.11 61.92" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2660.11 61.92L2643.08 202.83" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2660.11 61.92L2487.88 -55.18" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2643.08 202.83L2643.08 202.83" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2643.08 202.83L2511.98 71.9" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2643.08 202.83L2457.28 219.47" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2643.08 202.83L2612.12 -51.52" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2603.19 553.38L2603.19 553.38" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2603.19 553.38L2591.92 680.62" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2603.19 553.38L2641.59 378.66" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2603.19 553.38L2479.18 711.45" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2591.92 680.62L2591.92 680.62" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2591.92 680.62L2471.09 515.96" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <path d="M2591.92 680.62L2348.35 537.86" stroke="hsl(12, 10.6%, 100%)" stroke-width="1.5"></path>
                <circle r="25" cx="494.81" cy="709.36" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="963.58" cy="499.06" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="960.46" cy="673.57" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="1448.28" cy="385.76" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="1430.71" cy="516.25" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="1708.7" cy="363.69" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="2191.55" cy="669.88" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="2348.35" cy="537.86" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="2356.96" cy="650.18" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="2612.12" cy="-51.52" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="25" cx="2641.59" cy="378.66" fill="url(&quot;#SvgjsRadialGradient1391&quot;)"></circle>
                <circle r="5" cx="-86.98" cy="537.84" fill="#f5f3f2"></circle>
                <circle r="5" cx="-106.94" cy="650.73" fill="#f5f3f2"></circle>
                <circle r="5" cx="90.81" cy="500.1" fill="#f5f3f2"></circle>
                <circle r="5" cx="68.12" cy="667.28" fill="#f5f3f2"></circle>
                <circle r="5" cx="196.97" cy="561.61" fill="#f5f3f2"></circle>
                <circle r="5" cx="215.19" cy="702.38" fill="#f5f3f2"></circle>
                <circle r="5" cx="410.89" cy="548.78" fill="#f5f3f2"></circle>
                <circle r="5" cx="535.97" cy="556.96" fill="#f5f3f2"></circle>
                <circle r="5" cx="660" cy="701.04" fill="#f5f3f2"></circle>
                <circle r="5" cx="821.7" cy="550.75" fill="#f5f3f2"></circle>
                <circle r="5" cx="816.54" cy="684.64" fill="#f5f3f2"></circle>
                <circle r="5" cx="1159.48" cy="518.94" fill="#f5f3f2"></circle>
                <circle r="5" cx="1161.33" cy="680.43" fill="#f5f3f2"></circle>
                <circle r="5" cx="1312.26" cy="356.07" fill="#f5f3f2"></circle>
                <circle r="5" cx="1249.45" cy="546.31" fill="#f5f3f2"></circle>
                <circle r="5" cx="1258.9" cy="660.99" fill="#f5f3f2"></circle>
                <circle r="5" cx="1391.95" cy="679.67" fill="#f5f3f2"></circle>
                <circle r="5" cx="1590.85" cy="537.33" fill="#f5f3f2"></circle>
                <circle r="5" cx="1577.51" cy="675.97" fill="#f5f3f2"></circle>
                <circle r="5" cx="1745.9" cy="525.79" fill="#f5f3f2"></circle>
                <circle r="5" cx="1752.19" cy="653.43" fill="#f5f3f2"></circle>
                <circle r="5" cx="1860.87" cy="218.02" fill="#f5f3f2"></circle>
                <circle r="5" cx="1844.94" cy="408.89" fill="#f5f3f2"></circle>
                <circle r="5" cx="1878.88" cy="538.84" fill="#f5f3f2"></circle>
                <circle r="5" cx="1896.09" cy="711.35" fill="#f5f3f2"></circle>
                <circle r="5" cx="2009.23" cy="-41.73" fill="#f5f3f2"></circle>
                <circle r="5" cx="2007.94" cy="58.05" fill="#f5f3f2"></circle>
                <circle r="5" cx="2025.91" cy="223.92" fill="#f5f3f2"></circle>
                <circle r="5" cx="1990.17" cy="403.33" fill="#f5f3f2"></circle>
                <circle r="5" cx="2044.82" cy="537.85" fill="#f5f3f2"></circle>
                <circle r="5" cx="2009.24" cy="659.39" fill="#f5f3f2"></circle>
                <circle r="5" cx="2168.31" cy="-74.16" fill="#f5f3f2"></circle>
                <circle r="5" cx="2152.48" cy="80.43" fill="#f5f3f2"></circle>
                <circle r="5" cx="2161.55" cy="250.84" fill="#f5f3f2"></circle>
                <circle r="5" cx="2163.79" cy="343.18" fill="#f5f3f2"></circle>
                <circle r="5" cx="2199" cy="516.47" fill="#f5f3f2"></circle>
                <circle r="5" cx="2314.34" cy="-93.94" fill="#f5f3f2"></circle>
                <circle r="5" cx="2352.76" cy="53.72" fill="#f5f3f2"></circle>
                <circle r="5" cx="2340.5" cy="194.57" fill="#f5f3f2"></circle>
                <circle r="5" cx="2352.37" cy="357.01" fill="#f5f3f2"></circle>
                <circle r="5" cx="2487.88" cy="-55.18" fill="#f5f3f2"></circle>
                <circle r="5" cx="2511.98" cy="71.9" fill="#f5f3f2"></circle>
                <circle r="5" cx="2457.28" cy="219.47" fill="#f5f3f2"></circle>
                <circle r="5" cx="2511.83" cy="359" fill="#f5f3f2"></circle>
                <circle r="5" cx="2471.09" cy="515.96" fill="#f5f3f2"></circle>
                <circle r="5" cx="2479.18" cy="711.45" fill="#f5f3f2"></circle>
                <circle r="5" cx="2660.11" cy="61.92" fill="#f5f3f2"></circle>
                <circle r="5" cx="2643.08" cy="202.83" fill="#f5f3f2"></circle>
                <circle r="5" cx="2603.19" cy="553.38" fill="#f5f3f2"></circle>
                <circle r="5" cx="2591.92" cy="680.62" fill="#f5f3f2"></circle>
            </g>
            <defs>
                <mask id="SvgjsMask1390">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
                <radialGradient id="SvgjsRadialGradient1391">
                    <stop stop-color="#ffffff" offset="0.1"></stop>
                    <stop stop-color="rgba(234, 230, 229, 1)" offset="0.2"></stop>
                    <stop stop-color="rgba(234, 230, 229, 0)" offset="1"></stop>
                </radialGradient>
            </defs>
          </svg>
          <h1 className="hidden font-serif sm:block text-5xl text-white text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Media
          </h1>
          <div className="block font-serif sm:hidden text-4xl w-9/12 text-white text-center pb-12 absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-2/3">
            Media
          </div>
          <div className="hidden sm:block text-lg text-white text-center absolute top-1/2 left-1/2 -translate-x-1/2">
            Keep up with our latest news, tips and tricks, resources and more!
          </div>
          <div className="block sm:hidden w-10/12 text-md text-white text-center absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/6">
            Keep up with our latest news, tips and tricks, resources and more!
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-10/12 sm:w-5/12 grid grid-cols-1 gap-0">
            {cards.map((card, index) => (
              <MediaPost {...card} key={index} />
            ))}
        </div>
      </div>
    </>
  )
}

export default Media