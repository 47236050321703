import React from 'react'

import { Helmet } from 'react-helmet';

const TITLE = 'Contact Us - Benchmark Tutoring';

const Contact = () => {

  var script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', 'https://benchmarktutoring.teachworks.com/enquiry-form.js');
  document.body.appendChild(script);

  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11471164873">
        </script>
        <script type="text/javascript">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11471164873');`}
        </script>
        <script type="text/javascript">{`gtag('event', 'conversion', {'send_to': 'AW-11471164873/N6S-CK3zgYgZEMmr8N0q','value': 1.0,'currency': 'AUD'});`}
        </script>
      </Helmet>
      <div className="bg-[#8FCB9B] grid justify-center relative w-full overflow-hidden">
          <svg className="opacity-50" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" xmlnsSvgjs="http://svgjs.dev/svgjs" width="2500" height="560" preserveAspectRatio="none">
            <g mask="url(&quot;#SvgjsMask1066&quot;)" fill="none">
                <rect width="2500" height="560" x="0" y="0" fill="rgba(91, 146, 121, 1)"></rect>
                <use xlinkHref="#SvgjsSymbol1073" x="0" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1073" x="720" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1073" x="1440" y="0"></use>
                <use xlinkHref="#SvgjsSymbol1073" x="2160" y="0"></use>
            </g>
            <defs>
                <mask id="SvgjsMask1066">
                    <rect width="2500" height="560" fill="#ffffff"></rect>
                </mask>
                <path d="M-1 0 a1 1 0 1 0 2 0 a1 1 0 1 0 -2 0z" id="SvgjsPath1068"></path>
                <path d="M-3 0 a3 3 0 1 0 6 0 a3 3 0 1 0 -6 0z" id="SvgjsPath1069"></path>
                <path d="M-5 0 a5 5 0 1 0 10 0 a5 5 0 1 0 -10 0z" id="SvgjsPath1070"></path>
                <path d="M2 -2 L-2 2z" id="SvgjsPath1072"></path>
                <path d="M6 -6 L-6 6z" id="SvgjsPath1067"></path>
                <path d="M30 -30 L-30 30z" id="SvgjsPath1071"></path>
            </defs>
            <symbol id="SvgjsSymbol1073">
                <use xlinkHref="#SvgjsPath1067" x="30" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="30" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="30" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="30" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="30" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="30" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="30" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="30" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="30" y="510" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1068" x="30" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="90" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="90" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="90" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="90" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="90" y="270" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1070" x="90" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="90" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="90" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="90" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="90" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="150" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="150" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="150" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="150" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="150" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="150" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="150" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="150" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="150" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="150" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="210" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="210" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="210" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="210" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="270" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="270" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="270" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="270" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="270" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="330" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="330" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="330" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="330" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="330" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="330" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="330" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="330" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="330" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="330" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="390" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="390" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="390" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="390" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="390" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="390" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="390" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="390" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="390" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="390" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="450" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="450" y="90" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1070" x="450" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="450" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="450" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="450" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="450" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="450" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="450" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="450" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="510" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="510" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="510" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="510" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="510" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="510" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="510" y="390" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1070" x="510" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="510" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="510" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="570" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="570" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="570" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="570" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="570" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="570" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="570" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="570" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="570" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="570" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="630" y="30" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1069" x="630" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="630" y="150" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1067" x="630" y="210" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="630" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="630" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="630" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="630" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1069" x="630" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1072" x="630" y="570" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="690" y="30" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="690" y="90" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1068" x="690" y="150" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1071" x="690" y="210" stroke="rgba(143, 203, 155, 1)" stroke-width="3"></use>
                <use xlinkHref="#SvgjsPath1070" x="690" y="270" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="690" y="330" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="690" y="390" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="690" y="450" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1070" x="690" y="510" stroke="rgba(143, 203, 155, 1)"></use>
                <use xlinkHref="#SvgjsPath1067" x="690" y="570" stroke="rgba(143, 203, 155, 1)"></use>
            </symbol>
          </svg>
          <h1 className="hidden font-serif sm:block text-white absolute text-5xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Contact
          </h1>
          <div className="visible font-serif sm:hidden text-white absolute text-center leading-normal text-4xl top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
            Contact
          </div>
      </div>
      <div>
        <div className="flex justify-center">
          <div className="p-2 pt-20 justify-center text-center w-10/12"><span className="font-bold">To book a free trial lesson</span>, please use the form below (or contact us on Facebook) and include the following information:</div>
        </div>
        <div className="flex justify-center">
          <div className="p-2">- Subject and year level</div>
        </div>
        <div className="flex justify-center">
          <div className="p-2">- Preferred dates/times for the free trial lesson</div>
        </div>
        <div className="flex justify-center">
          <div className="p-2 pb-20">- Any other relevant information</div>
        </div>
        <div className="bg-white flex justify-center pt-6 pb-20 items-center">
          <div className="w-[200px]" id="teachworks-enquiry-form"></div>
        </div>
      </div>
    </>
  )
}

export default Contact